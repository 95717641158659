/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import convertRatio from "../../../utility/convertRatio";
import nullify from "../../../utility/nullify";
import context from "../../../context/context";
import ErrosToast from "../../../components/ErrosToast";

const calcTotal = (box) => {
  // console.log();
  return (
    parseInt(box.SZ01 || "0") +
    parseInt(box.SZ02 || "0") +
    parseInt(box.SZ03 || "0") +
    parseInt(box.SZ04 || "0") +
    parseInt(box.SZ05 || "0") +
    parseInt(box.SZ06 || "0") +
    parseInt(box.SZ07 || "0") +
    parseInt(box.SZ08 || "0") +
    parseInt(box.SZ09 || "0") +
    parseInt(box.SZ10 || "0") +
    parseInt(box.SZ11 || "0") +
    parseInt(box.SZ12 || "0")
  );
};
const InputBox = ({ box, newHeader, index }) => {
  let name, value;
  const [lot, setLot] = useState({ flag: false });
  const [lotTotal, setLotTotal] = useState("n");
  const ctx = useContext(context);
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_LINK +
          "/api/v1/lot/getOne?LOT=" +
          box.purchaseOrder.LOT,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        console.log("fetched lot");
        var lot = response.data.lot;
        setLot(lot);
        setLotTotal(
          Object.values(lot)
            .map((x) => parseInt(x))
            .reduce((c, n) => c + n)
        );
        // setSeperateLotRation(Object.values(lot).map((x) => parseInt(x)).filter((l)=>{}));
      })
      .catch((error) => console.log(error));
  }, []);
  const [userInput, setUserInput] = useState({
    SZ01: box.SZ01,
    SZ02: box.SZ02,
    SZ03: box.SZ03,
    SZ04: box.SZ04,
    SZ05: box.SZ05,
    SZ06: box.SZ06,
    SZ07: box.SZ07,
    SZ08: box.SZ08,
    SZ09: box.SZ09,
    SZ10: box.SZ10,
    SZ11: box.SZ11,
    SZ12: box.SZ12,
  });

  const [totalQty, setTotalQty] = useState(calcTotal(box));
  const handleTQty = (e) => {
    e.preventDefault();
    let totks = e.target.value;
    setTotalQty(totks);

    if (totks % lotTotal === 0) {
      const convertedValue = convertRatio(
        totks,
        Object.values(lot).map((x) => parseInt(x))
      );
      const finalValue = {
        SZ01: convertedValue[0],
        SZ02: convertedValue[1],
        SZ03: convertedValue[2],
        SZ04: convertedValue[3],
        SZ05: convertedValue[4],
        SZ06: convertedValue[5],
        SZ07: convertedValue[6],
        SZ08: convertedValue[7],
        SZ09: convertedValue[8],
        SZ10: convertedValue[9],
        SZ11: convertedValue[10],
        SZ12: convertedValue[11],
      };
      toast.remove();
      setUserInput(finalValue);

      console.log("new", ctx.boxData[index]);
      let temp = { ...ctx.boxData[index], ...finalValue };
      let tempBoxDataArray = [...ctx.boxData];
      tempBoxDataArray[index] = temp;
      ctx.setBoxData(tempBoxDataArray);
    } else {
      // alert("here ou can enter onl multiples of" + lotTotal)
      toast.remove();
      ErrosToast(
        `  ${totks} is Incorrect Lot ratio . \n Here you can enter only multiples of :` +
          lotTotal
      );
      // setTotalQty("");
    }
  };
  const handleUserInput = (e) => {
    name = e.target.name;
    value = parseInt(e.target.value) || "";
    let finalValue = { ...userInput, [name]: value };
    setUserInput(finalValue);
    let temp = { ...ctx.boxData[index], ...finalValue };
    let tempBoxDataArray = [...ctx.boxData];
    tempBoxDataArray[index] = temp;
    ctx.setBoxData(tempBoxDataArray);
    setTotalQty(
      Object.values({ ...userInput, [name]: value }).reduce((i, n) => {
        let first = i === "" ? 0 : parseInt(i);
        let second = n === "" ? 0 : parseInt(n);
        return first + second;
      })
    );
  };

  const lotRationInCell = (x) => {
    // x == 0 ? null : x;
    if (x === 0) {
      return null;
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            padding: "5px",
            color: "red",
            fontWeight: "bolder",
          }}
        >
          {nullify(x)}
        </div>
      );
    }
  };
  return (
    <>
      {!lot.flag && (
        <tr className="table-active">
          <th>Packed_qty/Box</th>
          <td>{box.purchaseOrder?.PO} </td>
          <td>{box.purchaseOrder?.SEA}</td>
          <td>{box.purchaseOrder?.STY}</td>
          <td>{box.purchaseOrder?.LOT}</td>
          <td>{box.purchaseOrder?.CLR}</td>
          <td>{box.purchaseOrder?.DIM}</td>
          <td>
            <div>
              <div
                style={{
                  textAlign: "center",
                  padding: "2.5px",
                  color: "red",
                  fontWeight: "bolder",
                }}
              >
                {lotTotal === 0 ? null : lotTotal}
              </div>

              <input
                style={{
                  marginTop: "5px",
                  textAlign: "center",
                  backgroundColor: "beige",
                  width: "50px",
                }}
                type={"text"}
                value={totalQty}
                onChange={handleTQty}
                name="totalQty"
                maxLength={4}
                disabled={box.purchaseOrder.LOT === "000"}
                autoComplete="off"
              />
              <div
                style={{
                  textAlign: "start",
                  padding: "5px",
                  color: "red",
                  fontWeight: "bolder",
                }}
              ></div>
            </div>
          </td>
          <td>
            {lotRationInCell(lot.SR01)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              value={nullify(userInput.SZ01)}
              onChange={handleUserInput}
              maxLength={4}
              name="SZ01"
              disabled={newHeader[0] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[0] === ""
                  ? "hidden"
                  : nullify(userInput.SZ01) === "" &&
                    box.purchaseOrder.LOT !== "000"
                  ? "hidden"
                  : userInput.SZ01
              }
              autoComplete="off"
            />
          </td>
          <td>
            {lotRationInCell(lot.SR02)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              maxLength={4}
              value={nullify(userInput.SZ02)}
              onChange={handleUserInput}
              name="SZ02"
              disabled={newHeader[1] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[1] === ""
                  ? "hidden"
                  : nullify(userInput.SZ02) === "" &&
                    box.purchaseOrder.LOT !== "000"
                  ? "hidden"
                  : userInput.SZ02
              }
              autoComplete="off"
            />
          </td>
          <td>
            {lotRationInCell(lot.SR03)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              maxLength={4}
              value={nullify(userInput.SZ03)}
              onChange={handleUserInput}
              name="SZ03"
              disabled={newHeader[2] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[2] === ""
                  ? "hidden"
                  : nullify(userInput.SZ03) === "" &&
                    box.purchaseOrder.LOT !== "000"
                  ? "hidden"
                  : userInput.SZ03
              }
              autoComplete="off"
            />
          </td>
          <td>
            {lotRationInCell(lot.SR04)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              maxLength={4}
              value={nullify(userInput.SZ04)}
              onChange={handleUserInput}
              name="SZ04"
              disabled={newHeader[3] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[3] === ""
                  ? "hidden"
                  : nullify(userInput.SZ04) === "" &&
                    box.purchaseOrder.LOT !== "000"
                  ? "hidden"
                  : userInput.SZ04
              }
              autoComplete="off"
            />
          </td>
          <td>
            {lotRationInCell(lot.SR05)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              maxLength={4}
              value={nullify(userInput.SZ05)}
              onChange={handleUserInput}
              name="SZ05"
              disabled={newHeader[4] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[4] === ""
                  ? "hidden"
                  : nullify(userInput.SZ05) === "" &&
                    box.purchaseOrder.LOT !== "000"
                  ? "hidden"
                  : userInput.SZ05
              }
              autoComplete="off"
            />
          </td>
          <td>
            {lotRationInCell(lot.SR06)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              maxLength={4}
              value={nullify(userInput.SZ06)}
              onChange={handleUserInput}
              name="SZ06"
              disabled={newHeader[5] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[5] === ""
                  ? "hidden"
                  : nullify(userInput.SZ06) === "" &&
                    box.purchaseOrder.LOT !== "000"
                  ? "hidden"
                  : userInput.SZ06
              }
              autoComplete="off"
            />
          </td>
          <td>
            {lotRationInCell(lot.SR07)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              maxLength={4}
              value={nullify(userInput.SZ07)}
              onChange={handleUserInput}
              name="SZ07"
              disabled={newHeader[6] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[6] === ""
                  ? "hidden"
                  : nullify(userInput.SZ07) === "" &&
                    box.purchaseOrder.LOT !== "000"
                  ? "hidden"
                  : userInput.SZ07
              }
              autoComplete="off"
            />
          </td>
          <td>
            {lotRationInCell(lot.SR08)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              maxLength={4}
              value={nullify(userInput.SZ08)}
              onChange={handleUserInput}
              name="SZ08"
              disabled={newHeader[7] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[7] === ""
                  ? "hidden"
                  : nullify(userInput.SZ08) === "" &&
                    box.purchaseOrder.LOT !== "000"
                  ? "hidden"
                  : userInput.SZ08
              }
              autoComplete="off"
            />
          </td>
          <td>
            {lotRationInCell(lot.SR09)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              maxLength={4}
              value={nullify(userInput.SZ09)}
              onChange={handleUserInput}
              name="SZ09"
              disabled={newHeader[8] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[8] === ""
                  ? "hidden"
                  : nullify(userInput.SZ09) === "" &&
                    box.purchaseOrder.LOT !== "000"
                  ? "hidden"
                  : userInput.SZ09
              }
              autoComplete="off"
            />
          </td>
          <td>
            {lotRationInCell(lot.SR10)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              maxLength={4}
              value={nullify(userInput.SZ10)}
              onChange={handleUserInput}
              name="SZ10"
              disabled={newHeader[9] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[9] === ""
                  ? "hidden"
                  : nullify(userInput.SZ10) === "" &&
                    box.purchaseOrder.LOT !== "000"
                  ? "hidden"
                  : userInput.SZ10
              }
              autoComplete="off"
            />
          </td>
          <td>
            {lotRationInCell(lot.SR11)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              maxLength={4}
              value={nullify(userInput.SZ11)}
              onChange={handleUserInput}
              name="SZ11"
              disabled={newHeader[10] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[10] === ""
                  ? "hidden"
                  : nullify(userInput.SZ11) === "" &&
                    box.purchaseOrder.LOT !== "000"
              }
              autoComplete="off"
            />
          </td>
          <td>
            {lotRationInCell(lot.SR12)}
            <input
              type={"text"}
              style={{ textAlign: "center" }}
              maxLength={4}
              value={nullify(userInput.SZ12)}
              onChange={handleUserInput}
              name="SZ12"
              disabled={newHeader[11] === "" || box.purchaseOrder.LOT !== "000"}
              className={
                newHeader[11] === ""
                  ? "hidden"
                  : nullify(userInput.SZ12) === "" &&
                    box.purchaseOrder.LOT !== "000"
              }
              autoComplete="off"
            />
          </td>
        </tr>
      )}
      <br />
    </>
  );
};
export default InputBox;
