import React from "react";
import { toast } from "react-hot-toast";

const ErrosToast = ( ErrorMessage ) => {
  console.log('error message', ErrorMessage)
   toast((t) => (
    <span>
      <button
        className="me-2"
        onClick={() =>
          // console.log(t)}
          toast.dismiss(t.id)
        }
      >
        ❌
      </button>
      {ErrorMessage}
    </span>
  ));

  //   toast.remove();
  //   showToast();
  // return showToast();
};

export default ErrosToast;
