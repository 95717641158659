import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";

import Header from "../../components/header";
import axios from "axios";
import toast from "react-hot-toast";
import Loader from "../../components/Loader";
import context from "../../context/context";
import convertTimeToCountry from "../../utility/convertTimeToCountry";
import { useCallback } from "react";
import { debounce, filter } from "underscore";
import ErrosToast from "../../components/ErrosToast";

function CsvRow({
  entry,
  index,
  sendToSent,
  setCsvSent,
  setCsvUnsent,
  csvSent,
  csvUnsent,
  setIsUpdateShipmentNo,
  updateData,
  isChanged,
}) {
  const company_shipment_num = entry.csn;
  const [companyShipmentNo, setCSN] = useState("");
  const [isInputDisabled, setIsInputDisabled] = useState(!!entry.csn);

  const [generating, setGenerating] = useState(false);
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [isShipinfoLoading, setIsShipinfoLoading] = useState(false);

  // const handleCompanyShipmentInput = (e) => {
  //   //console.log('enabkle?', e.target.disabled)
  //   setCSN(e.target.value.toUpperCase());
  //   // console.log(company_shipment_num);
  //   //setIsBlocking(true);
  // };
  useEffect(() => {
    setCSN(entry.csn);

    return () => {};
  }, []);

  const handleUpdate = () => {
    // for updating updatedDate row
    console.log("is handle update calling");
    const updatedData = { updatedAt: new Date().toISOString() };
    console.log("updated data date", updatedData);
    updateData(entry.ShipNo, updatedData);
  };

  return (
    <>
      {/* {console.log("entryy", entry)} */}
      <tr className="table-active">
        <td>{index + 1}</td>
        <td>{entry.username}</td>
        <td>{entry.ShipNo}</td>
        <td
          className="table-cell"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "11vh",
            border: "none",
          }}
        >
          <div className="d-flex justify-content-center align-items-center">
            {/* <Prompt
        when={isBlocking}
        message={() =>
          'Are you sure, you want to leave? All your data will be lost!'
        }
      /> */}
            <input
              className={`form-control`}
              type="text"
              name="shipmentInput"
              style={{ marginRight: "20px" }}
              value={companyShipmentNo}
              onChange={(e) => setCSN(e.target.value.toUpperCase())}
              // onBlur={(e) =>
              //   handleOnblur(
              //     e.target.value,

              //     company_shipment_num,
              //     companyShipmentNo
              //   )
              // }
              // required

              placeholder="Min 5 byte "
              minLength={5}
              maxLength={8}
              disabled={isInputDisabled}
              autoComplete="off"
            />
            {isInputDisabled ? (
              <button
                className={`btn ${isInputDisabled ? "btn-primary" : ""}`}
                onClick={() => {
                  //alert("Functionality is pending");
                  setIsInputDisabled(false);
                  // setCSN(company_shipment_num);
                  // console.log("csn", company_shipment_num);
                  // console.log(entry);
                  // setIsInputHilight(true);
                  //setIsBlocking(true)
                }}
              >
                {/* {isInputDisabled && "Modify" : "Modify"} */}
                Edit
              </button>
            ) : null}
          </div>
        </td>
        <td>{convertTimeToCountry(entry.createdAt)}</td>
        <td>{convertTimeToCountry(entry.updatedAt)}</td>
        <td>
          <button
            className="btn btn-none"
            onClick={async () => {
              // console.log('compnay shipment number', companyShipmentNo)
              console.log("csv sent status", csvSent);
              // console.log('csn', entry.csn);
              console.log("is input desible", isInputDisabled);

              if (csvSent === true && entry.csn !== null && isInputDisabled) {
                toast.remove();
                return ErrosToast(
                  "CSV/ShipCsv can not be downloaded, you need to edit it first"
                );
              }
              if (companyShipmentNo === null || companyShipmentNo.length < 5) {
                // console.log(
                //   `entryyyyyyyyyyyyyyyy  : ${entry.csn}  : ${companyShipmentNo}`
                // );
                toast.remove();
                return ErrosToast(
                  "Lerros shipment should be at least 5 characters"
                );
              }
              if (companyShipmentNo == entry.csn) {
                toast.remove();
                return ErrosToast(
                  "Change the lerros shipment to download new Csv/ShipCsv"
                );
              } else {
                try {
                  //setIsBlocking(false)
                  setIsCsvLoading(true);
                  // try{
                  //   const response = await axios.get(
                  //     `/csv/convert?shipNo=${entry.ShipNo}&csn=${companyShipmentNo}`
                  //   );
                  //   const data = response.data
                  // }
                  await axios
                    .get(`/csv/convert`, {
                      params: {
                        shipNo: entry.ShipNo,
                        csn: companyShipmentNo,
                        modifyCsn: company_shipment_num,
                        timezone: localStorage.getItem("timezone"),
                      },
                    })
                    .then((res) => {
                      console.log("res", res);

                      if (csvSent == false && csvUnsent == true) {
                        sendToSent(entry.ShipNo);
                        setCSN("");
                      } else {
                        setCSN(companyShipmentNo);
                        setIsInputDisabled(true);
                      }
                      setIsUpdateShipmentNo((prev) => !prev);
                      setTimeout(() => {
                        window.location.replace(
                          process.env.REACT_APP_API_LINK +
                            "/api/v1/csv/download?oneTimeToken=" +
                            res.data.downloadTokens[0]
                        );
                      }, 1000);
                      window.location.replace(
                        process.env.REACT_APP_API_LINK +
                          "/api/v1/csv/download?oneTimeToken=" +
                          res.data.downloadTokens[1]
                      );

                      // setIsCsvLoading(false);
                      handleUpdate();
                      // console.log("in", isInputDisabled);
                    });
                } catch (err) {
                  console.log(err);
                  setIsCsvLoading(false);
                  if (
                    err.response.data ===
                    "Company Shipment Number Already exists"
                  ) {
                    alert(" Given Company Shipment Number Already exists");
                    setIsCsvLoading(false);
                  } else alert("Something went wrong!");
                } finally {
                  setIsCsvLoading(false);
                }
                // setCsvSent(false);
                // setCsvUnsent(true);
                //handleNavigation();
              }
            }}
            disabled={isCsvLoading}
          >
            {isCsvLoading ? (
              <div className="spinner-border">
                <span class="visually-hidden">Loading..</span>
              </div>
            ) : (
              <img
                width="54"
                height="54"
                src="https://img.icons8.com/ultraviolet/40/import-csv.png"
                alt="import-csv"
              />
            )}
          </button>
        </td>

        <td>
          <button
            className="btn btn-none"
            onClick={() => {
              setIsExcelLoading(true);
              axios
                .get(
                  `${process.env.REACT_APP_API_LINK}/api/v1/csv/summary?shipNo=${entry.ShipNo}&csn=${companyShipmentNo}`,
                  {
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                  }
                )
                .then((res) => {
                  window.location.replace(
                    process.env.REACT_APP_API_LINK +
                      "/api/v1/csv/download?oneTimeToken=" +
                      res.data.downloadToken
                  );
                  setIsExcelLoading(false);
                })
                .catch((err) => {
                  setIsExcelLoading(false);
                  console.log(err);
                  alert("No entry found");
                });
            }}
            disabled={isExcelLoading}
          >
            {isExcelLoading ? (
              <div className="spinner-border">
                <span className="visually-hidden">Loading..</span>
              </div>
            ) : (
              <img
                width="54"
                height="54"
                src="https://img.icons8.com/color/48/export-excel.png"
                alt="export-excel"
              />
            )}
          </button>
        </td>
        {/* <td>
          <button
            className="btn btn-none"
            onClick={() => {
              setIsShipinfoLoading(true);

              axios
                .get(`/shipInfo/convert`, {
                  params: {
                    shipNo: entry.ShipNo,

                    shipGroup: companyShipmentNo,
                  },
                })
                .then((res) => {
                  window.location.replace(
                    process.env.REACT_APP_API_LINK +
                      "/api/v1/shipInfo/download?oneTimeToken=" +
                      res.data.downloadToken
                  );
                  console.log("result hai kya", res);
                  setIsShipinfoLoading(false);
                })
                .catch((err) => {
                  setIsShipinfoLoading(false);
                  console.log(err);
                });

              //handleNavigation();
            }}
          >
            {isShipinfoLoading ? (
              <div className="spinner-border">
                <span class="visually-hidden">Loading..</span>
              </div>
            ) : (
              <img
                width="54"
                height="54"
                src="https://img.icons8.com/ultraviolet/40/import-csv.png"
                alt="import-csv"
              />
            )}
          </button>
        </td> */}
      </tr>
    </>
  );
}
const ExportCsv = () => {
  const [loading, setLoading] = useState(true);
  const [filteredEntries, setFilteredEntries] = useState([]);
  // const [isInputHilite, setIsInputHilite] = useState(false);

  const [totalEntries, setTotalEntries] = useState([]);
  const [sentEntries, setSentEntries] = useState([]);

  const [userForSearch, setUserForSearch] = useState("");
  const [shipNoForSearch, setShipNoForSearch] = useState("");

  const [isUpdateShipmentNo, setIsUpdateShipmentNo] = useState(false);

  const [initialFetchDone, setInitialFetchDone] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  const [changedRowId, setChangedRowId] = useState(null); // for updating the table row only

  const ctx = useContext(context);
  const { userData } = useContext(context);

  const sendToSent = (shipno, i) => {
    //console.log(shipno);
    setFilteredEntries((prev) => prev.filter((el, i) => el.ShipNo !== shipno));
    //console.log("filterdone");
  };

  const { csvSent, setCsvSent, csvUnsent, setCsvUnsent } = useContext(context);

  console.log("filter entreis", filteredEntries);
  const updateData = (id, newData) => {
    setFilteredEntries((prevData) =>
      prevData.map((row) => (row.ShipNo === id ? { ...row, ...newData } : row))
    );
    setChangedRowId(id);
  };

  const handleFilter = async (sent, unsent, pageNo, limit) => {
    setLoading(true);
    try {
      const res = await axios.get(
        `/barcode/getTransferData?&sent=${sent.toString()}&unsent=${unsent.toString()}&ShipNo=${shipNoForSearch}&username=${userForSearch}&pageNo=${pageNo}&docNo=${limit}`
      );
      //console.log("ressssssssss", res);
      // ctx.setPageCountTransferData(Math.ceil(res.data.pageCount));
      // ctx.setCurrentPageTransferData(0);
      setFilteredEntries(res.data.transferData);
      // setLoading(false);
      setPage(pageNo);

      if (res.data.transferData.length < limit) {
        setHasMore(false);
      }
      setInitialFetchDone(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  //console.log('transfer data ', filteredEntries)

  useEffect(() => {
    // handleFilter(csvSent, csvUnsent, 0, 30);
    if (!initialFetchDone) {
      handleFilter(csvSent, csvUnsent, 0, 30);
      // setInitialFetchDone(true); // Set initialFetchDone to true after initial fetch
      return;
    }
    const tableContainer = document.querySelector(".table-container");
    if (!tableContainer) return;

    const handleScroll = () => {
      const table = tableContainer.querySelector(".table");

      if (!table) return;

      const tableBody = table.querySelector("tbody");

      // Add this check
      if (!tableBody) return;

      const lastRow = tableBody.querySelector("tr:last-child");
      console.log("use effect calling.");
      if (lastRow) {
        // console.log('last row calling in useEffect')
        // console.log("boxapi length", filteredEntries.length);
        const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
        const containerOffset =
          tableContainer.scrollTop + tableContainer.clientHeight;
        const bottomOffset = 20;

        if (
          containerOffset > lastRowOffset - bottomOffset &&
          hasMore &&
          !loading
        ) {
          console.log("api calling from useEffect");
          handleFilter(
            csvSent,
            csvUnsent,
            0,
            30 * ((filteredEntries.length + 30) / 30)
          );
        }
      }
    };

    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [page, loading]);

  // useEffect(() => {
  //   // console.log("use effect running after ");
  //   console.log('am i called1??')
  //   handleFilter(csvSent, csvUnsent, 0, 30);
  //   // setLoading(true)
  // }, [csvSent, csvUnsent, isUpdateShipmentNo]);

  console.log("sent check", csvSent);

  //   axios
  //     .get("/barcode/getTransferData?sent=true&unsent=true")
  //     .then((res) => {
  //       console.log("total Entries", res.data.transferData)
  //       setTotalEntries(res.data.transferData);
  //       const temp_tot_data = res.data.transferData
  //       // Make the second API call here, nested inside the first .then() block
  //       axios
  //         .get("/barcode/getTransferData?sent=true&unsent=false")
  //         .then((res) => {
  //           // temp_tot_data.filter((data)=> )
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [csvSent, csvUnsent]);

  // const handlePageClick = (e) => {
  //   // for pagination handle function
  //   setLoading(true);
  //   axios
  //     .get(
  //       `/barcode/getTransferData?pageNo=${
  //         e.selected
  //       }&docNo=${30}&sent=${csvSent.toString()}&unsent=${csvUnsent.toString()}&ShipNo=${shipNoForSearch}&username=${userForSearch}`
  //     )
  //     .then((res) => {
  //       //ctx.setPageCountTransferData(Math.ceil((res.data.entries.length/30)+1));
  //       ctx.setCurrentPageTransferData(e.selected);
  //       setFilteredEntries(res.data.transferData);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       // Handle error if the axios request fails
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //     });
  // };

  const handleFilterForSearch = (
    e,
    shipNoForSearch,
    userForSearch,
    csvSent,
    csvUnsent
  ) => {
    setLoading(true);
    const encodedShipNo = encodeURIComponent(shipNoForSearch);
    const encodedUser = encodeURIComponent(userForSearch);
    axios
      .get(
        `/barcode/getTransferData?sent=${csvSent.toString()}&unsent=${csvUnsent.toString()}&ShipNo=${encodedShipNo}&username=${encodedUser}&pageNo=${0}&docNo=${30}`
      )
      .then((res) => {
        //  console.log("ressssssssss", res);
        // ctx.setPageCountTransferData(Math.ceil(res.data.pageCount));
        // ctx.setCurrentPageTransferData(0);
        setFilteredEntries(res.data.transferData);
        setLoading(false);
        // setHasMore(true);
        if (res.data.transferData.length < 30) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const OnChangeHandleFilter = useCallback(
    // for filter by every letter
    debounce(
      (e, shipNoForSearch, userForSearch, csvSent, csvUnsent) =>
        handleFilterForSearch(
          e,
          shipNoForSearch,
          userForSearch,
          csvSent,
          csvUnsent
        ),
      1000,
      false
    ),
    []
  );

  const handleReset = () => {
    setLoading(true);
    setInitialFetchDone(false);
    axios
      .get(
        `/barcode/getTransferData?pageNo=${0}&docNo=${30}&sent=${csvSent.toString()}&unsent=${csvUnsent.toString()}`
      )
      .then((res) => {
        //  console.log("checking correct data or not", res);

        setShipNoForSearch("");
        setUserForSearch("");
        // ctx.setPageCountTransferData(Math.ceil(res.data.pageCount));
        // ctx.setCurrentPageTransferData(0);
        setFilteredEntries(res.data.transferData);
        if (res.data.transferData.length < 30) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  // console.log("filter entries", filteredEntries);
  // console.log('checking sent csv or not ', csvSent)
  // console.log('unsent checking', csvUnsent)

  return (
    <div>
      <div className="fixed-top">
        <div>
          <Header />
        </div>
        <div
          className="d-flex justify-content-start"
          style={{
            // border: "2px solid red",
            width: "100%",
            padding: "0% 1% 1% 2%",
            background: "#fff",
          }}
        >
          {!!userData.admin && (
            <div
              className="d-flex row "
              style={{
                textTransform: "capitalize",
                // border: "2px solid green",
                width: "243px",
              }}
            >
              <div className="p-2">
                <label className="fs-6">User Name</label>
              </div>
              <input
                size="sm"
                type="Search"
                name="user"
                className="ms-2 form-control form-control-sm"
                // required
                placeholder="User"
                maxLength={10}
                // id="input-search"
                value={userForSearch}
                onChange={(e) => {
                  setUserForSearch(e.target.value);
                  OnChangeHandleFilter(
                    e,
                    shipNoForSearch,
                    // csnForSearch,
                    e.target.value,
                    csvSent,
                    csvUnsent
                  );
                }}
                style={{
                  border: "2px solid #000",
                  fontWeight: "bold",
                  width: "200px",
                }}
                autoComplete="off"
              />
            </div>
          )}
          <div
            className="d-flex row "
            style={{
              textTransform: "capitalize",
              // border: "2px solid red",
              width: "20%",
            }}
          >
            <div className="p-2">
              <label
                className="fs-6 mb-0"
                style={{
                  width: "70%",
                  // border: "2px solid blue"
                }}
              >
                Factory Shipment
              </label>
            </div>
            <input
              size="sm"
              type="Search"
              // id="inputh"
              className="ms-2 form-control form-control-sm"
              name="shipment"
              value={shipNoForSearch}
              placeholder="Factory Shipment"
              minLength={5}
              maxLength={20}
              onChange={(e) => {
                setShipNoForSearch(e.target.value);
                OnChangeHandleFilter(
                  e,
                  e.target.value,
                  // csnForSearch,
                  userForSearch,
                  csvSent,
                  csvUnsent
                );
              }}
              style={{
                border: "2px solid #000",
                fontWeight: "bold",
                width: "200px",
              }}
              autoComplete="off"
            />
          </div>
          <div>
            <button
              // style={{ width: "80px" }}
              className="btn btn-primary mt-5 "
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleFilterForSearch(
                  e,
                  shipNoForSearch,
                  userForSearch,
                  csvSent,
                  csvUnsent
                );
              }}
            >
              Filter
            </button>
          </div>
          <div>
            <button
              className="ms-3 btn btn-danger mt-5"
              type="button"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
          <div className="mt-5 ms-5">
            <input
              className="form-check-input me-2 mt-2"
              size={"large"}
              type="checkbox"
              value={csvUnsent}
              checked={csvUnsent}
              onChange={(e) => {
                setCsvUnsent((prev) => !prev);
                setInitialFetchDone(false);
                OnChangeHandleFilter(
                  e,
                  shipNoForSearch,
                  userForSearch,
                  csvSent,
                  e.target.checked
                );
              }}
              label=" UnSentCsv"
            />
            <label className="fw-bold fs-5">UnsentCsv</label>
          </div>
          <div className="mt-5 ms-5">
            <input
              className="form-check-input me-2 mt-2"
              type="checkbox"
              checked={csvSent}
              value={csvSent}
              onChange={(e) => {
                setCsvSent((prev) => !prev);
                setInitialFetchDone(false);
                OnChangeHandleFilter(
                  e,
                  shipNoForSearch,
                  userForSearch,
                  e.target.checked,
                  csvUnsent
                );
              }}
              label="SentCsv"
            />
            <label className="fw-bold fs-5">SentCsv</label>
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "200px" }}>
        {loading && !initialFetchDone ? (
          <Loader />
        ) : filteredEntries && filteredEntries.length === 0 ? (
          <h3 style={{ textAlign: "center" }}>No Records Found!</h3>
        ) : (
          <div>
            <div className="mx-4 mt-1 table-responsive tableFixHead table-container">
              <table className="table table-bordered  table-hover  table-sm table-header-for-all">
                <thead>
                  <tr className="table-warning">
                    <th>S.No.</th>
                    <th>User Name</th>
                    <th>Factory Shipment</th>
                    <th>Lerros Shipment</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Csv/ShipInfo</th>
                    {/* <th>Barcode</th> */}
                    <th>Excel</th>
                    {/* <th>ShipInfo</th> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredEntries.map((entry, index) => {
                    // console.log("index ", index);
                    //  console.log('entry entry', entry)
                    return (
                      <CsvRow
                        key={entry.ShipNo}
                        index={index}
                        entry={entry}
                        sendToSent={sendToSent}
                        setCsvUnsent={setCsvUnsent}
                        setCsvSent={setCsvSent}
                        csvSent={csvSent}
                        csvUnsent={csvUnsent}
                        setIsUpdateShipmentNo={setIsUpdateShipmentNo}
                        updateData={updateData} // callback for update the table row
                        isChanged={entry.ShipNo === changedRowId} // for checking it should update or not
                      />
                    );
                  })}
                </tbody>
              </table>
              {loading && initialFetchDone && (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {/* {!loading && !hasMore && <div>No more data</div>} */}
              {/* {!!ctx.pageCountTransferData && (
                <ReactPaginate //ctx.pageCountPoSummary &&
                  disableInitialCallback={true}
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={ctx.pageCountTransferData}
                  forcePage={ctx.currentPageTransferData}
                  // initialPage={ctx.currentPage}
                  previousLabel="< prev"
                  renderOnZeroPageCount={null}
                  marginPagesDisplayed={2}
                  containerClassName="pagination justify-content-center"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  activeClassName="active"
                />
              )} */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExportCsv;
