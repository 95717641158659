import { useState } from "react";
import Header from "../../components/header";
import BarChart from "./BarChart";

const Reports = () => {
  const [formData, setFormData] = useState({
    etdFrom: "",
    koll: "",
    factory: "",
    agent: "",
    style: "",
    div: "",
    openedPo: false,
    closedPo: false,
    exFactory: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  return (
    <div>
      <div className="fixed-top">
        <div>
          <Header />
        </div>
      </div>
      <div style={{ marginTop: "110px" }}>
        <form className="mx-4 ">
          <div className="form-row d-flex row row-cols-2 row-cols-lg-5 g-2 g-lg-3">
            <div>
              <label className="fw-bold fs-5" htmlFor="etdFrom">
                ETD From:
              </label>
              <select
                id="etdFrom"
                name="etdFrom"
                value={formData.etdFrom}
                onChange={handleChange}
              >
                <option value="">Select</option>
              </select>
            </div>

            <div>
              <label className="fw-bold fs-5" htmlFor="koll">
                Koll:
              </label>
              <select
                id="koll"
                name="koll"
                value={formData.koll}
                onChange={handleChange}
              >
                <option value="">Select</option>
              </select>
            </div>
            <div>
              <label className="fw-bold fs-5" htmlFor="factory">
                Factory:
              </label>
              <select
                id="factory"
                name="factory"
                value={formData.factory}
                onChange={handleChange}
              >
                <option value="">Select</option>
              </select>
            </div>
            <div>
              <label className="fw-bold fs-5" htmlFor="agent">
                Agent:
              </label>
              <select
                id="agent"
                name="agent"
                value={formData.agent}
                onChange={handleChange}
              >
                <option value="">Select</option>
              </select>
            </div>
            <div>
              {" "}
              <label className="fw-bold fs-5" htmlFor="style">
                Style:
              </label>
              <select
                id="style"
                name="style"
                value={formData.style}
                onChange={handleChange}
              >
                <option value="">Select</option>
              </select>
            </div>
            <div>
              <label className="fw-bold fs-5" htmlFor="div">
                Div:
              </label>
              <select
                id="div"
                name="div"
                value={formData.div}
                onChange={handleChange}
              >
                <option value="">Select</option>
              </select>
            </div>
            <div>
              <label className="fw-bold fs-5" htmlFor="openedPo">
                Opened PO:
              </label>
              <input
                type="checkbox"
                id="openedPo"
                name="openedPo"
                checked={formData.openedPo}
                onChange={handleChange}
              />
            </div>

            <div>
              {" "}
              <label className="fw-bold fs-5" htmlFor="closedPo">
                Closed PO:
              </label>
              <input
                type="checkbox"
                id="closedPo"
                name="closedPo"
                checked={formData.closedPo}
                onChange={handleChange}
              />
            </div>
            <div>
              {" "}
              <label className="fw-bold fs-5" htmlFor="exFactory">
                ExFactory:
              </label>
              <input
                type="checkbox"
                id="exFactory"
                name="exFactory"
                checked={formData.exFactory}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
      </div>

      <BarChart />
    </div>
  );
};
export default Reports;
