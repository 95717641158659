import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
// import InputBox from "./InputBox";
import axios from "axios";
import Header from "../../components/header";
import Context from "../../context/context";
import { useContext } from "react";
import formatDate from "../../utility/formatDate";
import Loader from "../../components/Loader";
import ReactPaginate from "react-paginate";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import debounce from "lodash.debounce"; // this debounce is for getting data after given time span
import context from "../../context/context";
import ErrosToast from "../../components/ErrosToast";
function Checkbox({ value, handleCheckboxChange, selectedPo, checked }) {
  const [cb, setCb] = useState(checked);
  return (
    <Form.Check
      // style={{ backgroundColor: "red" }}

      type="checkbox"
      value={value}
      checked={cb}
      onChange={(e) => {
        const success = handleCheckboxChange(e);
        if (success) setCb((prev) => !prev);
      }}
      style={{
        // width: "15px",
        // height: "15px",
        marginLeft: "15px",
        // marginTop: "12px",
      }}
    />
  );
}

const DisplayPurchase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const PO = queryParams.get("PO");
  const ctx = useContext(Context);
  const { userData } = useContext(context);
  const [dspData, setDspData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [limit] = useState(30);
  const [btnLoading, setBtnLoading] = useState(false);

  const [emojiOperator, setEmojiOperator] = useState({
    fact: "⬆",
    sup: "⬆",
    agent: "⬆",
    po: "⬆",
    fcus: "⬆",
  });
  const [suggestions, setSuggestions] = useState({
    PO: [],
    STY: [],
    CLR: [],
    LOT: [],
    DIM: [],
    FACT: [],
    SUP: [],
    AGNT: [],
  }); // for suggestion while filtering data

  const [isSuggestionClicked, setIsSuggestionClicked] = useState(false); // for filter data when suggestionClicked
  const [cc, setCc] = useState({
    PO: -1,
    STY: -1,
    CLR: -1,
    LOT: -1,
    DIM: -1,
    FACT: -1,
    SUP: -1,
    AGNT: -1,
  }); // state for current cursor

  const [initialFetchDone, setInitialFetchDone] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const [shouldFetch, setShouldFetch] = useState(false);
  const [isCheckBoxClicked, setIsCheckBoxClicked] = useState(false);
  const [isSortingClicked, setIsSortingClicked] = useState(false);

  const [order, setOrder] = useState([]);

  // const [filterClicked, setFilterClicked] = useState(false);
  const { filterClicked, setFilterClicked } = ctx;

  const columnMapping = {
    fact: "FACT",
    sup: "SUP",
    agent: "AGNT",
    po: "PO",
    fcus: "FCUS",
  };
  // console.log("body ctx id data", ctx.id);
  const fetchData = async (pageNo, limit) => {
    //setFilterClicked(false);
    setIsLoading(false);
    try {
      if (PO || ctx.id) {
        // console.log('PO after reset', PO)
        const body = {
          PO: PO || ctx.id.PO || "",
          SEA: ctx.id.SEA || "",
          STY: ctx.id.STY || "",
          LOT: ctx.id.LOT || "",
          CLR: ctx.id.CLR || "",
          DIM: ctx.id.DIM || "",
          FACT: ctx.id.FACT || "",
          SUP: ctx.id.SUP || "",
          AGNT: ctx.id.AGNT || "",
        };

        //ctx.setPageCount()
        // ctx.setId(body);
        const res = await axios.post("/po/getFilteredPurchaseOrders", {
          ...body,
          open: ctx.openCheck,
          processed: ctx.procesCheck,
          pageNo: pageNo,
          docNo: limit,
          // order: [
          //   ["FACT", "DESC"],
          //   ["SUP", "DESC"],
          //   ["AGNT", "DESC"],
          //   ["PO", "ASC"],
          //   ["FCUS", "DESC"],
          // ],
          order: order ? order : null,
        });
        ctx.setId(body);
        // ctx.setCurrentPage(0);
        ctx.setPoDataDSP(res.data.poData);
        setDspData(res.data.poData);
        setPage(pageNo);
        if (res.data.poData.length < limit) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setInitialFetchDone(true);
        // ctx.setPageCount(Math.ceil(res.data.pageCount));
        // setIsLoading(true);
      } else {
        const data = ctx.poDataDSP;
        //console.log('use Effect dsp po data', data)
        // if (data.length === 0) {
        // const asc = emojiOperator.fact
        // axios.post("/po/getPurchaseOrders", {
        //     //...data,
        //     open: ctx.openCheck,
        //     processed: ctx.procesCheck,
        //     pageNo: 0,
        //     docNo: limit,
        //     order: [
        //       ["FACT", "ASC"],
        //       ["SUP", "ASC"],
        //       ["AGNT", "ASC"],
        //       ["PO", "ASC"],
        //       ["ETA", "ASC"],
        //     ],
        //   })
        const res = await axios.post(`/po/getPurchaseOrders`, {
          ...ctx.id,
          pageNo: 0,
          docNo: limit,
          open: ctx.openCheck,
          processed: ctx.procesCheck,
        });
        ctx.setPoDataDSP(res.data.poData);
        setDspData(res.data.poData);
        // ctx.setId({
        //   PO: "",
        //   SEA: "",
        //   STY: "",
        //   LOT: "",
        //   CLR: "",
        //   DIM: "",
        //   FACT: "",
        //   SUP: "",
        //   AGNT: "",
        // });
        // ctx.setPageCount(Math.ceil(res.data.pageCount));
        // setPage(pageNo);
        if (res.data.poData.length < limit) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        setInitialFetchDone(true);
        //console.log("sec res", res.data);
        //setTotalPageCount(ctx.pageCount)
        // setIsLoading(true);
        // } else {
        //   console.log('susususususu')
        //   console.log('limit check', limit)
        //   setDspData(ctx.poDataDSP);
        //   setPage(pageNo);
        //   if (dspData.length < limit) {
        //     setHasMore(false);
        //   }
        //   setInitialFetchDone(true);
        //   // setIsLoading(true);
        // }
        //else will assign ctx data
        //setIsLoading(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(true);
    }
  };
  // console.log("filtered thing??", filterClicked);
  useEffect(() => {
    if (!initialFetchDone) {
      // console.log("is it calling ");
      setIsLoading(false);
      fetchData(0, 30);
      // setTimeout(()=> {
      //   setInitialFetchDone(true);
      // },500)
      // Set initialFetchDone to true after initial fetch
      return;
    }

    const tableContainer = document.querySelector(".table-container");
    if (!tableContainer) return;

    const handleScroll = () => {
      const table = tableContainer.querySelector(".table");

      if (!table) return;

      const tableBody = table.querySelector("tbody");

      // Add this check
      if (!tableBody) return;

      const lastRow = tableBody.querySelector("tr:last-child");

      if (lastRow) {
        const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
        const containerOffset =
          tableContainer.scrollTop + tableContainer.clientHeight;
        const bottomOffset = 20;
        // console.log('checking last data or not', containerOffset > lastRowOffset - bottomOffset)
        // if(containerOffset > lastRowOffset - bottomOffset)
        // console.log("filer thing true or not inside useEffect", filterClicked);
        if (
          containerOffset > lastRowOffset - bottomOffset &&
          hasMore &&
          isLoading &&
          isCheckBoxClicked
        ) {
          filterOnCheck(
            ctx.openCheck,
            ctx.procesCheck,
            0,
            30 * ((dspData.length + 30) / 30)
          );
        } else if (
          containerOffset > lastRowOffset - bottomOffset &&
          hasMore &&
          isLoading &&
          filterClicked
        ) {
          // console.log("elese if called or handleFIlter called");
          // const handleFilter = async (e, restValues, pageNo, limit)
          handleFilter("", ctx.id, 0, 30 * ((dspData.length + 30) / 30));
        } else if (
          containerOffset > lastRowOffset - bottomOffset &&
          hasMore &&
          isLoading &&
          !filterClicked
        ) {
          // console.log("fetch data called in useEffect");
          fetchData(0, 30 * ((dspData.length + 30) / 30));
        }
      }
    };

    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [
    page,
    isLoading,
    filterClicked,
    initialFetchDone,
    isCheckBoxClicked,
    isSortingClicked,
  ]);

  // console.log('filter from useEffect', filterClicked)
  // console.log('loading from useEffect', isLoading)
  // console.log('hasMore from useEffect', hasMore)

  useEffect(() => {
    if (ctx.selectedCheckboxes.length === 0) ctx.setSelectedPo("");
    // if (ctx.poData.length === 0) ctx.setSelectedPo("");
  }, [ctx.selectedCheckboxes]);

  useEffect(() => {
    if (shouldFetch) {
      handleFilter("", ctx.id, 0, 30);
      setShouldFetch(false);
    }
  }, [shouldFetch]);
  console.log("selected check box", ctx.selectedCheckboxes);
  console.log("selected po", ctx.selectedPo);
  const handleCheckboxChange = (e) => {
    const checkboxValue = e.target.value;

    // getting value of id and PO
    console.log("checkbox value", checkboxValue);
    console.log("dsp data", dspData);
    const poNumber = checkboxValue.split("*")[1]; // extracting po numb er, Logic-> checkbox value format is poId*poNumber
    const poId = checkboxValue.split("*")[0]; // extracting po id, Logic-> checkbox value format is poId*poNumber

    if (ctx.selectedPo === "") {
      //console.log("empty");
      ctx.setSelectedPo(poNumber);
      if (!ctx.selectedCheckboxes.includes(poId)) {
        // po id is not in array then  push in po id
        ctx.setSelectedCheckboxes((prevSelected) => [...prevSelected, poId]);
      } else {
        ctx.setSelectedCheckboxes(
          (prevSelected) => prevSelected.filter((value) => value !== poId) //if poid  already then remove from id
        );
      }
      return true;
    } else {
      //console.log(ctx.selectedPo, poNumber, ctx.selectedPo === poNumber);
      if (ctx.selectedPo === poNumber) {
        if (!ctx.selectedCheckboxes.includes(poId)) {
          // po id is not in array then  push in po id
          ctx.setSelectedCheckboxes((prevSelected) => [...prevSelected, poId]);
        } else {
          ctx.setSelectedCheckboxes(
            (prevSelected) => prevSelected.filter((value) => value !== poId) //if poid  already then remove from id
          );
        }
        return true;
      } else {
        toast.remove();

        ErrosToast("One PO is already selected, you can select only same PO");
        return false;
      }
    }
  };
  // 3 aug
  // const [id, setId] = useState({
  //   PO: "",
  //   SEA: "",
  //   STY: "",
  //   LOT: "",
  //   CLR: "",
  //   DIM: "",
  //   FACT: "",
  //   SUP: "",
  //   AGNT: "",
  // });

  const handleCheckOpen = () => {
    let op = !ctx.openCheck;
    //console.log("openCurrent", op);
    ctx.setOpenCheck(op);
    filterOnCheck(op, ctx.procesCheck);
  };

  const handleCheckProcessed = () => {
    let pc = !ctx.procesCheck;
    //console.log("processCurrent", pc);
    ctx.setProcesCheck(pc);
    filterOnCheck(ctx.openCheck, pc);
  };

  const filterOnCheck = async (openCheck, procesCheck, page, limit) => {
    setIsCheckBoxClicked(true);
    let checkFilerClicked;
    if (
      ctx.id.PO !== "" ||
      ctx.id.SEA !== "" ||
      ctx.id.STY !== "" ||
      ctx.id.LOT !== "" ||
      ctx.id.CLR !== "" ||
      ctx.id.DIM !== "" ||
      ctx.id.FACT !== "" ||
      ctx.id.SUP !== "" ||
      ctx.id.AGNT !== ""
    ) {
      checkFilerClicked = true;
    } else {
      checkFilerClicked = false;
    }
    // PO || ctx.id.PO || "",
    // SEA: ctx.id.SEA || "",
    // STY: ctx.id.STY || "",
    // LOT: ctx.id.LOT || "",
    // CLR: ctx.id.CLR || "",
    // DIM: ctx.id.DIM || "",
    // FACT: ctx.id.FACT || "",
    // SUP: ctx.id.SUP || "",
    // AGNT: ctx.id.AGNT || "",

    // setFilterClicked(true);
    // setIsLoading(false);
    // setInitialFetchDone(false);
    //ctx.setCurrentPage(0);
    // if (PO) {
    //   const body = {
    //     PO: PO,
    //     SEA: "",
    //     STY: "",
    //     LOT: "",
    //     CLR: "",
    //     DIM: "",
    //     FACT: "",
    //     SUP: "",
    //     AGNT: "",
    //   };
    //   ctx.setId(body);
    //   axios.post("/po/getFilteredPurchaseOrders", {
    //       ...body,
    //       open: ctx.openCheck,
    //       processed: ctx.procesCheck,
    //       pageNo: 0,
    //       docNo: limit,
    //       //order: [order],
    //     })
    //     .then((res) => {
    //       console.log('body', body)
    //       console.log('data on click', res)
    //       ctx.setId(body);
    //       ctx.setPoDataDSP(res.data.poData);
    //       setDspData(res.data.poData);
    //       ctx.setPageCount(Math.ceil(res.data.pageCount));
    //       setIsLoading(true);
    //     })
    //     .catch((error) => console.log(error));
    // }

    if (filterClicked === true || PO || checkFilerClicked) {
      axios
        .post(
          `/po/getFilteredPurchaseOrders`,
          {
            ...ctx.id,
            open: openCheck,
            processed: procesCheck,
            pageNo: 0,
            docNo: limit ? limit : 30,
            order: order ? order : "",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        )
        // axios.post(
        //   `/po/getFilteredPurchaseOrders?pageNo=${0}&docNo=${limit}&open=${openCheck}&processed=${procesCheck}`
        // )
        .then((res) => {
          // ctx.setCurrentPage(0);
          ctx.setPoDataDSP(res.data.poData);
          setDspData(res.data.poData);
          // ctx.setPageCount(Math.ceil(res.data.pageCount));
          // if (res.data.poData.length < 30) {
          //   setHasMore(false);
          // } else {
          setHasMore(true);
          // }
          // setIsLoading(true);
          setIsCheckBoxClicked(false);
        })
        .catch((err) => {
          setIsLoading(true);
          console.log(err);
        });
    } else {
      axios
        .post(`/po/getPurchaseOrders`, {
          pageNo: 0,
          docNo: limit ? limit : 30,
          open: openCheck,
          processed: procesCheck,
          order: order ? order : "",
        })
        .then((res) => {
          ctx.setPoDataDSP(res.data.poData);
          setDspData(res.data.poData);
          // ctx.setPageCount(Math.ceil(res.data.pageCount));
          // ctx.setCurrentPage(0);
          // if (res.data.poData.length < 30) {
          //   setHasMore(false);
          // } else {
          setHasMore(true);
          // }
          // setIsLoading(true);
          setIsCheckBoxClicked(false);
        })
        .catch((error) => {
          setIsLoading(true);
        });
    }
  };

  //console.log('filtered clicked', filterClicked)
  console.log("process check true or false", ctx.procesCheck);
  const handleFilter = async (
    e,
    restValues,
    pageNo,
    limit,
    openCheck,
    procesCheck
  ) => {
    //console.log('handle filter e', e)
    // console.log("rest values", restValues);
    // console.log("filter field data", ctx.id);
    if (e) {
      e.preventDefault();
    }

    //ctx.setId({ ...restValues, [name]: value });
    // console.log('ctx id data from filter', ctx.id)
    // if (name === "PO") {
    //   value = value.trim(" ").replace(/\D/g, "");
    // } else {
    //   value = value.trim(" ").toUpperCase();
    // }
    console.log("filter clicked!");
    // if (
    //   restValues.AGNT === "" &&
    //   restValues.CLR === "" &&
    //   restValues.DIM === "" &&
    //   restValues.FACT === "" &&
    //   restValues.LOT === "" &&
    //   restValues.PO === "" &&
    //   restValues.SEA === "" &&
    //   restValues.STY === "" &&
    //   restValues.SUP === ""
    // ) {
    //   console.log("no data found");
    //   toast.remove();
    //   return;
    // }
    // setIsLoading(false);
    // setInitialFetchDone(false);
    setIsLoading(false);
    setFilterClicked(true);
    const res = await axios.post("/po/getFilteredPurchaseOrders", {
      ...restValues,
      open: openCheck || ctx.openCheck,
      processed: procesCheck || ctx.procesCheck,
      pageNo: pageNo,
      docNo: limit,
      // order: order ? order : null
    });
    //console.log("filtered data", res);
    // ctx.setCurrentPage(0);
    ctx.setPoDataDSP(res.data.poData);
    setDspData(res.data.poData);
    // ctx.setPageCount(Math.ceil(res.data.pageCount));
    if (res.data.poData.length < limit) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    setIsLoading(true);
    // setIsLoading(true);
    // console.log("dsppppp" ,res.data.poData )
  };
  // console.log("latest ctx id", ctx.id);

  const OnChangeHandleFilter = useCallback(
    // for filter by every letter
    debounce(
      (e, restValues, openCheck, procesCheck) =>
        handleFilter(e, restValues, 0, 30, openCheck, procesCheck),
      1000,
      false
    ),
    []
  );

  // useEffect(() => {
  //   // This effect will run every time ctx.id.PO changes
  //   // Ensure that OnChangeHandleFilter is called after ctx.setId
  //   OnChangeHandleFilter(); // Pass null for e as it's not being used in OnChangeHandleFilter
  // }, [ctx.id]);

  // useEffect(() => {
  //   console.log("Updated state values:");
  //   console.log("openCheck", ctx.openCheck);
  //   console.log("procesCheck", ctx.procesCheck);
  // }, [ctx.openCheck, ctx.procesCheck]);

  // useEffect(() => {
  //   if (ctx.selectedCheckboxes.length === 0) ctx.setSelectedPo("");
  //   // if (ctx.poData.length === 0) ctx.setSelectedPo("");
  // }, [ctx.selectedCheckboxes]);

  // console.log('set Po data', ctx.poData)
  // console.log('set Po data', ctx.poData.length)
  // console.log('selected po', ctx.selectedPo)
  // console.log('selected checkboxes', ctx.selectedCheckboxes)
  const handleReset = async () => {
    // try {
    if (ctx.poData.length < 1) {
      ctx.setSelectedCheckboxes([]);
      ctx.setSelectedPo("");
    }
    queryParams.delete("PO");
    // setDspData([])
    setFilterClicked(false);
    // setIsLoading(false);
    // console.log("hey I am called");
    // setIsLoading(false)
    setInitialFetchDone(false);
    setHasMore(true);
    ctx.setId({
      PO: "",
      SEA: "",
      STY: "",
      LOT: "",
      CLR: "",
      DIM: "",
      FACT: "",
      SUP: "",
      AGNT: "",
    });
    ctx.setOpenCheck(true);
    ctx.setProcesCheck(false);

    // const searchParams = new URLSearchParams()
    navigate("/DisplayPurchase");
    // setTimeout(() => fetchData(0, 30), 2000);
    // await fetchData(0, 30);

    // if (dspData.length < 30) {
    //   setHasMore(false);
    // }
    // else{
    //   setHasMore(true)
    // }

    //   const result = await axios.post(`/po/getPurchaseOrders`, {
    //     pageNo: 0,
    //     docNo: limit,
    //     open: true,
    //     processed: false,
    //   });

    //   ctx.setPoDataDSP(result.data.poData);
    //   ctx.setId({
    //     PO: "",
    //     SEA: "",
    //     STY: "",
    //     LOT: "",
    //     CLR: "",
    //     DIM: "",
    //     FACT: "",
    //     SUP: "",
    //     AGNT: "",
    //   });
    //   setDspData(result.data.poData);
    //   // ctx.setCurrentPage(0);
    //   ctx.setOpenCheck(true);
    //   ctx.setProcesCheck(false);
    //   // ctx.setPageCount(Math.ceil(result.data.pageCount));
    //   // setPage(pageNo);
    //   if (result.data.poData.length < 30) {
    //     setHasMore(false);
    //   }

    //   // setIsLoading(true);

    // // Create a new URL with the updated parameters
    // const newUrl = `${window.location.pathname}`;

    // // Replace the current URL without reloading the page
    // window.history.replaceState({}, '', newUrl);

    // ctx.setSelectedCheckboxes(
    //   ctx.poData.length !== 0
    //     ? ctx.selectedCheckboxes &&
    //         toast(
    //           "There is PO locked in boxEnteries.\n\n Hence the checkBoxes will be there",
    //           {
    //             duration: 3000,
    //           }
    //         )
    //     : ""
    // );
    //   //console.log('current page', ctx.currentPage)
    // } catch (error) {
    //   setIsLoading(true);
    //   console.error("Error resetting data:", error);
    // }
  };

  useEffect(() => {
    //putting this because from po summary to dsp valued right!
    ctx.setFilterClicked(ctx.filterClicked);
  }, [ctx.filterClicked]);

  const handlePageClick = (e) => {
    setIsLoading(false);
    if (filterClicked === true) {
      axios
        .post(
          `/po/getFilteredPurchaseOrders`,
          {
            ...ctx.id,
            open: ctx.openCheck,
            processed: ctx.procesCheck,
            pageNo: e.selected,
            docNo: limit,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
          }
        )
        // axios.post(
        //   `/po/getFilteredPurchaseOrders?pageNo=${0}&docNo=${limit}&open=${openCheck}&processed=${procesCheck}`
        // )
        .then((res) => {
          ctx.setPoDataDSP(res.data.poData);
          setDspData(res.data.poData);
          ctx.setPageCount(Math.ceil(res.data.pageCount));
          ctx.setCurrentPage(e.selected);
          setIsLoading(true);
        })
        .catch((err) => {
          setIsLoading(true);
          console.log(err);
        });
    } else {
      axios
        .post(`/po/getPurchaseOrders`, {
          pageNo: e.selected,
          docNo: limit,
          open: ctx.openCheck,
          processed: ctx.procesCheck,
        })
        .then((res) => {
          ctx.setPoDataDSP(res.data.poData);
          setDspData(res.data.poData);
          ctx.setPageCount(Math.ceil(res.data.pageCount));
          ctx.setCurrentPage(e.selected);
          //ctx.setCurrentPage(ctx.currentPage);
          setIsLoading(true);
        })
        .catch((error) => {
          // Handle error if the axios request fails
          console.error("Error fetching data:", error);
          setIsLoading(true);
        });
    }
  };

  const handleProceed = async () => {
    if (ctx.userData.admin === 1) {
      return alert("Admin cannot do entry");
    } else if (ctx.userData.orders !== 1) {
      return alert(
        "You are not authorized to access this. Please contact to administrator"
      );
    } else if (ctx.selectedCheckboxes.length === 0) {
      alert("Select PO to proceed");
    } else {
      //setFilterClicked(false);
      const res = await axios.get("admin/getAvailableFileCount");
      if (res.data.totalFileCount > 0) {
        return alert(
          "Master files are required to actualize. Please contact Admin."
        );
      }
      if (ctx.poData.length !== 0) {
        alert(
          "There are some locked entries, Please release it before making new entries"
        );
        navigate("/orders");
        return;
      }
      //setIsLoading(false);
      setBtnLoading(true);

      try {
        const res = await axios.post(
          process.env.REACT_APP_API_LINK + "/api/v1/po/getLockedPurchaseOrders",
          {
            POIDs: ctx.selectedCheckboxes,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("po cant make entry debugging", res.data);
        if (res.data.poData.length === 0) {
          setBtnLoading(false);

          alert(
            "This PO has been processed with its maximum limit entry, hence you cant make entry anymore."
          );

          return;
        }
        ctx.setLastBCGeneratedSNo(res.data.lastBCGeneratedSNo);
        ctx.setIsFilter(true);
        ctx.setPoData(res.data.poData);
        ctx.setOvershipValue(res.data.overshipValue);
        localStorage.setItem("PO", res.data.poData[0].PO);
        ctx.setLastTo(res.data.lastValueOfTo);
        // console.log("lastoftwo", res.data.lastValueOfTo);
        setBtnLoading(false);
        navigate("/orders");
      } catch (err) {
        console.log(err);
        setBtnLoading(false);
        ErrosToast(err.response.data.message);
      }
    }
  };

  const emojiOperatorChange = (e) => {
    //setFilterClicked(false);
    // setIsSortingClicked((prev) => !prev)
    const name = e.target.dataset.name;
    if (name === "fact") {
      setEmojiOperator({
        ...emojiOperator,
        fact: emojiOperator.fact === "⬆" ? "⬇" : "⬆",
      });
    } else if (name === "agent") {
      setEmojiOperator({
        ...emojiOperator,
        agent: emojiOperator.agent === "⬆" ? "⬇" : "⬆",
      });
    } else if (name === "po") {
      setEmojiOperator({
        ...emojiOperator,
        po: emojiOperator.po === "⬆" ? "⬇" : "⬆",
      });
    } else if (name === "fcus") {
      setEmojiOperator({
        ...emojiOperator,
        fcus: emojiOperator.fcus === "⬆" ? "⬇" : "⬆",
      });
    } else if (name === "sup") {
      setEmojiOperator({
        ...emojiOperator,
        sup: emojiOperator.sup === "⬆" ? "⬇" : "⬆",
      });
    }
    const colOperator =
      Object.entries(emojiOperator).find(
        ([column, operator]) => column === name
      ) || [];
    //console.log('one operator',colOperator)
    const orderLocal = [colOperator].map(([column, operator]) => {
      console.log("inside map", column, operator);
      return [columnMapping[column], operator === "⬆" ? "ASC" : "DESC"];
    });
    setOrder(orderLocal);
    //console.log("order", order);
    // setInitialFetchDone(false);
    if (PO || filterClicked) {
      const body = {
        PO: PO || ctx.id.PO || "",
        SEA: ctx.id.SEA || "",
        STY: ctx.id.STY || "",
        LOT: ctx.id.LOT || "",
        CLR: ctx.id.CLR || "",
        DIM: ctx.id.DIM || "",
        FACT: ctx.id.FACT || "",
        SUP: ctx.id.SUP || "",
        AGNT: ctx.id.AGNT || "",
      };
      ctx.setId(body);
      setIsSortingClicked(true);
      axios
        .post("/po/getFilteredPurchaseOrders", {
          ...body,
          open: ctx.openCheck,
          processed: ctx.procesCheck,
          pageNo: 0,
          docNo: 30,
          order: [orderLocal],
        })
        .then((res) => {
          ctx.setId(body);
          ctx.setPoDataDSP(res.data.poData);
          setDspData(res.data.poData);
          // ctx.setPageCount(Math.ceil(res.data.pageCount));
          // if (res.data.poData.length < 30) {
          //   setHasMore(false);
          // }
          setHasMore(true);
          setIsLoading(true);
          setIsSortingClicked(false);
        })
        .catch((error) => {
          setIsLoading(true);
          console.log(error);
        });
    }
    // else if (filterClicked === true) {
    //   axios
    //     .post(
    //       `/po/getFilteredPurchaseOrders`,
    //       {
    //         ...ctx.id,
    //         pageNo: 0,
    //         open: ctx.openCheck,
    //         processed: ctx.procesCheck,
    //         order: [order],
    //         docNo: 30,
    //       },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem("token")}`,
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     )
    //     // axios.post(
    //     //   `/po/getFilteredPurchaseOrders?pageNo=${0}&docNo=${limit}&open=${openCheck}&processed=${procesCheck}`
    //     // )
    //     .then((res) => {
    //       ctx.setPoDataDSP(res.data.poData);
    //       setDspData(res.data.poData);
    //       ctx.setPageCount(Math.ceil(res.data.pageCount));
    //       // if (res.data.poData.length < 30) {
    //       //   setHasMore(false);
    //       // }
    //       setHasMore(true);
    //       setIsLoading(true);
    //     })
    //     .catch((err) => {
    //       setIsLoading(true);
    //       console.log(err);
    //     });
    // }
    else {
      setIsSortingClicked(true);
      axios
        .post(`/po/getPurchaseOrders`, {
          pageNo: 0,
          open: ctx.openCheck,
          processed: ctx.procesCheck,
          order: [orderLocal],
          docNo: limit,
        })
        .then((res) => {
          ctx.setPoDataDSP(res.data.poData);
          setDspData(res.data.poData);
          ctx.setId({
            PO: "",
            SEA: "",
            STY: "",
            LOT: "",
            CLR: "",
            DIM: "",
            FACT: "",
            SUP: "",
            AGNT: "",
          });
          // ctx.setPageCount(Math.ceil(res.data.pageCount));
          //console.log("sec res", res.data);
          // if (res.data.poData.length < 30) {
          //   setHasMore(false);
          // }
          setHasMore(true);
          setIsLoading(true);
          setIsSortingClicked(false);
        })
        .catch((error) => {
          setIsLoading(true);
          console.log(error);
        });
    }
  };

  // const handleSuggestionClick = async (key, suggestion, e) => {
  //   // console.log('handle suggestion click', e.target.value)
  //   setIsSuggestionClicked((prev) => !prev);
  //   // console.log('suggestion', suggestion)
  //   // console.log('before set id', ctx.id)
  //   ctx.setId((poIds) => ({
  //     ...poIds,
  //     [key]: suggestion,
  //   }));
  //   setSuggestions((prevSuggestions) => ({
  //     ...prevSuggestions,
  //     [key]: [],
  //   }));

  //   // console.log("prevv" ,suggestion )
  //   // console.log('after set id', ctx.id)
  // };

  //const isFirstRender = useRef(true); // for not rendering for the first time. this is used in useEffect.

  // useEffect(     // for filter everytime when click on suggestions filter.
  //   (e) => {
  //     if(isFirstRender.current){
  //       isFirstRender.current = false;
  //       return;
  //     }
  //     handleFilter(e);
  //   },
  //   [isSuggestionClicked]
  // );

  // const handleKeyDown = (e, key) => {
  //   // console.log('suggestion key', suggestions[key][2])
  //   let name = key;
  //   if (e.key === "ArrowDown") {
  //     if (suggestions[key].length === 0 || suggestions[key].length === cc[key] + 1)
  //       return null;
  //     var x = cc[key] + 1;
  //     setCc({...cc, [name]: x});
  //     // ctx.setId({ ...ctx.id, [name]: value });
  //     ctx.setId({ ...ctx.id, [name]: suggestions[key][x] });
  //     // setId(data[x].sty);
  //   } else if (e.key === "ArrowUp") {
  //     if (suggestions[key].length === 0 || cc[key] === 0) return null;
  //     if (cc[key] === -1) {
  //       setCc(suggestions[key].length);
  //       // setId(data[data.length].sty);
  //       ctx.setId({ ...ctx.id, [name]: suggestions[key][suggestions[key].length] });
  //     } else {
  //       x = cc[key] - 1;
  //       setCc({...cc, [name]: x});
  //       // setId(data[x].sty);
  //       ctx.setId({ ...ctx.id, [name]: suggestions[key][x] });
  //     }
  //   } else if (e.key === "Enter") {
  //     if (cc[key] !== -1) {
  //       // console.log(data[cc].sty);
  //       handleFilter(e);
  //       // setData([]);
  //     }
  //   }
  // };

  // const handleSuggestionShow = (key)=> {
  //   setSuggestions((prevSuggestions) => ({
  //     ...prevSuggestions,
  //     [key]: [],
  //   }));
  // }

  // console.log('dsp data', dspData)
  // console.log("ctx id", ctx.id);
  //console.log('suggestion', suggestions)

  return (
    <>
      <div className="fixed-top">
        <div className="">
          <Header />
        </div>

        <div
          style={{
            background: "white",
            paddingBottom: "10px",
            // border: "2px solid red",
            width: "100%",
            // marginLeft: "1%",
            padding: " 1% 2.5% 1% 2%",
          }}
        >
          <Form onSubmit={(e) => handleFilter(e)}>
            <Row style={{ textTransform: "capitalize" }}>
              <Col>
                <Form.Group>
                  <Form.Label className="fs-6">PO#</Form.Label>
                  <Form.Control
                    size="sm"
                    type="Search"
                    name="PO"
                    // required
                    placeholder="#PO"
                    maxLength={6}
                    id="input-search"
                    value={ctx.id.PO}
                    //onChange={handleUserPO}
                    style={{ border: "2px solid #000", fontWeight: "bold" }}
                    onChange={(e) => {
                      // Update state using ctx.setId
                      ctx.setId((prevId) => {
                        const updatedId = {
                          ...prevId,
                          PO: e.target.value.trim(" ").replace(/\D/g, ""),
                        };
                        // Call OnChangeHandleFilter after state has been updated
                        OnChangeHandleFilter(
                          e,
                          updatedId,
                          ctx.openCheck,
                          ctx.procesCheck
                        );
                        return updatedId; // Return the updated state
                      });
                    }}
                    autoComplete="off"
                    // onKeyDown={(e) => handleKeyDown(e, 'PO')}
                    // onBlur={()=>handleSuggestionShow('PO')}
                  />
                  {/* {suggestions.PO.length > 0 && (
                    <ul
                      className=""
                      style={{
                        maxHeight: "200px",
                        backgroundColor: "white",
                        listStyleType: 'none',
                        margin: 0,
                        padding: 0,
                        overflow: "auto",
                        scrollbarWidth: "thin",
                      }}
                    >
                      {suggestions.PO.map((suggestion, index) => (
                        <li
                          key={index}
                          onClick={(e) =>
                            handleSuggestionClick("PO", suggestion, e)
                          }
                          className={cc['PO'] === index ? "dropdown-item active-suggestion" : "dropdown-item"}
                          ref={(ref) => {
                            if (ref && cc['PO'] === index) {
                              // Scroll the active item into view
                              ref.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                                inline: "start",
                              });
                            }
                          }}
                        >
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  )} */}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className="fs-6">Style</Form.Label>
                  <Form.Control
                    size="sm"
                    type="Search"
                    id="input-search"
                    name="STY"
                    value={ctx.id.STY}
                    placeholder="#STY"
                    maxLength={8}
                    onChange={(e) => {
                      // Update state using ctx.setId
                      ctx.setId((prevId) => {
                        const updatedId = {
                          ...prevId,
                          STY: e.target.value.trim(" ").toUpperCase(),
                        };
                        // Call OnChangeHandleFilter after state has been updated
                        OnChangeHandleFilter(
                          e,
                          updatedId,
                          ctx.openCheck,
                          ctx.procesCheck
                        );
                        return updatedId; // Return the updated state
                      });
                    }}
                    style={{ border: "2px solid #000", fontWeight: "bold" }}
                    autoComplete="off"
                    // onKeyDown={(e) => handleKeyDown(e, 'STY')}
                    // onBlur={()=>handleSuggestionShow('STY')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className="fs-6">Color</Form.Label>
                  <Form.Control
                    size="sm"
                    type="Search"
                    id="input-search"
                    name="CLR"
                    value={ctx.id.CLR}
                    placeholder="#CLR"
                    onChange={(e) => {
                      // Update state using ctx.setId
                      ctx.setId((prevId) => {
                        const updatedId = {
                          ...prevId,
                          CLR: e.target.value.trim(" ").toUpperCase(),
                        };
                        // Call OnChangeHandleFilter after state has been updated
                        OnChangeHandleFilter(
                          e,
                          updatedId,
                          ctx.openCheck,
                          ctx.procesCheck
                        );
                        return updatedId; // Return the updated state
                      });
                    }}
                    maxLength={3}
                    style={{ border: "2px solid #000", fontWeight: "bold" }}
                    autoComplete="off"
                    // onKeyDown={(e) => handleKeyDown(e, 'CLR')}
                    // onBlur={()=>handleSuggestionShow('CLR')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className="fs-6">Lot</Form.Label>
                  <Form.Control
                    size="sm"
                    type="Search"
                    id="input-search"
                    name="LOT"
                    value={ctx.id.LOT}
                    placeholder="#LOT"
                    onChange={(e) => {
                      // Update state using ctx.setId
                      ctx.setId((prevId) => {
                        const updatedId = {
                          ...prevId,
                          LOT: e.target.value.trim(" ").toUpperCase(),
                        };
                        // Call OnChangeHandleFilter after state has been updated
                        OnChangeHandleFilter(
                          e,
                          updatedId,
                          ctx.openCheck,
                          ctx.procesCheck
                        );
                        return updatedId; // Return the updated state
                      });
                    }}
                    maxLength={3}
                    style={{ border: "2px solid #000", fontWeight: "bold" }}
                    autoComplete="off"
                    // onKeyDown={(e) => handleKeyDown(e, 'LOT')}
                    // onBlur={()=>handleSuggestionShow('LOT')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className="fs-6">Dim</Form.Label>
                  <Form.Control
                    size="sm"
                    type="Search"
                    id="input-search"
                    name="DIM"
                    value={ctx.id.DIM}
                    placeholder="#DIM"
                    onChange={(e) => {
                      // Update state using ctx.setId
                      ctx.setId((prevId) => {
                        const updatedId = {
                          ...prevId,
                          DIM: e.target.value.trim(" ").toUpperCase(),
                        };
                        // Call OnChangeHandleFilter after state has been updated
                        OnChangeHandleFilter(
                          e,
                          updatedId,
                          ctx.openCheck,
                          ctx.procesCheck
                        );
                        return updatedId; // Return the updated state
                      });
                    }}
                    maxLength={3}
                    style={{ border: "2px solid #000", fontWeight: "bold" }}
                    autoComplete="off"
                    // onKeyDown={(e) => handleKeyDown(e, 'DIM')}
                    // onBlur={()=>handleSuggestionShow('DIM')}
                  />
                </Form.Group>
              </Col>
              {!(
                ctx.userData.factory == null || ctx.userData.factory === ""
              ) || (
                <Col>
                  <Form.Group>
                    <Form.Label className="fs-6">Factory</Form.Label>
                    <Form.Control
                      size="sm"
                      type="Search"
                      id="input-search"
                      name="FACT"
                      value={ctx.id.FACT}
                      placeholder="#FACT"
                      onChange={(e) => {
                        // Update state using ctx.setId
                        ctx.setId((prevId) => {
                          const updatedId = {
                            ...prevId,
                            FACT: e.target.value.trim(" ").toUpperCase(),
                          };
                          // Call OnChangeHandleFilter after state has been updated
                          OnChangeHandleFilter(
                            e,
                            updatedId,
                            ctx.openCheck,
                            ctx.procesCheck
                          );
                          return updatedId; // Return the updated state
                        });
                      }}
                      maxLength={5}
                      style={{ border: "2px solid #000", fontWeight: "bold" }}
                      autoComplete="off"
                      // onKeyDown={(e) => handleKeyDown(e, 'FACT')}
                      // onBlur={()=>handleSuggestionShow('FACT')}
                    />
                  </Form.Group>
                </Col>
              )}
              {!(
                ctx.userData.supplier == null || ctx.userData.supplier === ""
              ) || (
                <Col>
                  <Form.Group>
                    <Form.Label className="fs-6">supplier</Form.Label>
                    <Form.Control
                      size="sm"
                      type="Search"
                      name="SUP"
                      id="input-search"
                      value={ctx.id.SUP}
                      placeholder="#SUP"
                      maxLength={5}
                      onChange={(e) => {
                        // Update state using ctx.setId
                        ctx.setId((prevId) => {
                          const updatedId = {
                            ...prevId,
                            SUP: e.target.value.trim(" ").toUpperCase(),
                          };
                          // Call OnChangeHandleFilter after state has been updated
                          OnChangeHandleFilter(
                            e,
                            updatedId,
                            ctx.openCheck,
                            ctx.procesCheck
                          );
                          return updatedId; // Return the updated state
                        });
                      }}
                      style={{ border: "2px solid #000", fontWeight: "bold" }}
                      autoComplete="off"
                      // onKeyDown={(e) => handleKeyDown(e, 'SUP')}
                      // onBlur={()=>handleSuggestionShow('SUP')}
                    />
                  </Form.Group>
                </Col>
              )}
              {!(ctx.userData.agent == null || ctx.userData.agent === "") || (
                <Col>
                  <Form.Group>
                    <Form.Label className="fs-6">Agent</Form.Label>
                    <Form.Control
                      size="sm"
                      type="Search"
                      id="input-search"
                      name="AGNT"
                      value={ctx.id.AGNT}
                      placeholder="#AGNT"
                      onChange={(e) => {
                        // Update state using ctx.setId
                        ctx.setId((prevId) => {
                          const updatedId = {
                            ...prevId,
                            AGNT: e.target.value.trim(" ").toUpperCase(),
                          };
                          // Call OnChangeHandleFilter after state has been updated
                          OnChangeHandleFilter(
                            e,
                            updatedId,
                            ctx.openCheck,
                            ctx.procesCheck
                          );
                          return updatedId; // Return the updated state
                        });
                      }}
                      maxLength={5}
                      style={{ border: "2px solid #000", fontWeight: "bold" }}
                      autoComplete="off"
                      // onKeyDown={(e) => handleKeyDown(e, 'AGNT')}
                      // onBlur={()=>handleSuggestionShow('AGNT')}
                    />
                  </Form.Group>
                </Col>
              )}
              <Col>
                <Form.Label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Form.Label>

                <Form.Check
                  type="checkbox"
                  checked={ctx.openCheck}
                  value="open"
                  onChange={handleCheckOpen}
                  label="Open"
                />
              </Col>
              <Col

              // xs={4} md={2}
              >
                <Form.Label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Form.Label>

                <Form.Check
                  siz="lg
                    "
                  type="checkbox"
                  value="processed"
                  checked={ctx.procesCheck}
                  onChange={handleCheckProcessed}
                  label="Processed"
                />
              </Col>
              <Col className="mt-4">
                {/* <Form.Label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Form.Label> */}

                <Button
                  style={{ width: "80px" }}
                  className="btn btn-primary "
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();

                    // Filter out empty fields
                    const filteredValues = Object.entries(ctx.id)
                      .filter(([key, value]) => value !== "")
                      .reduce((obj, [key, value]) => {
                        obj[key] = value;
                        return obj;
                      }, {});

                    handleFilter(e, filteredValues);
                  }}
                >
                  Filter
                </Button>
              </Col>
              <Col className="mt-4">
                {/* <Form.Label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Form.Label> */}

                <Button
                  style={{ width: "80px" }}
                  className="btn btn-danger "
                  type="reset"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Col>
              <Col className="mt-4">
                {/* <Form.Label>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Form.Label> */}
                <Button
                  style={{ width: "100px" }}
                  className="btn btn-success text-center "
                  type="button"
                  onClick={handleProceed}
                  disabled={btnLoading || userData.admin == 1}
                >
                  {btnLoading ? <Spinner size="sm" /> : "Process"}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      <div
        className="mx-4 tableFixHead table-container"
        style={{ marginTop: "200px", marginBottom: "40px" }}
      >
        {!isLoading && !initialFetchDone ? (
          <Loader />
        ) : (
          dspData && (
            <table className="table table-bordered table-hover table-sm table-header-for-all">
              {(ctx.openCheck || ctx.procesCheck) && (
                <thead>
                  <tr className="table-warning">
                    {userData.user == 1 && (
                      <th style={{ width: "10px" }}>Select</th>
                    )}
                    <th
                      data-name="fact"
                      onClick={emojiOperatorChange}
                    >{`Factory${emojiOperator.fact}`}</th>
                    <th
                      data-name="sup"
                      onClick={emojiOperatorChange}
                    >{`Supplier${emojiOperator.sup}`}</th>
                    <th
                      data-name="agent"
                      onClick={emojiOperatorChange}
                    >{`Agent${emojiOperator.agent}`}</th>
                    <th
                      data-name="po"
                      onClick={emojiOperatorChange}
                    >{`PO#${emojiOperator.po}`}</th>
                    <th
                      data-name="fcus"
                      onClick={emojiOperatorChange}
                    >{`For_Cust${emojiOperator.fcus}`}</th>
                    <th>Sea</th>
                    <th>Style</th>
                    <th>Lot</th>
                    <th>Clr</th>
                    <th>Dim</th>
                    <th>Tot_Qty</th>
                    <th>Tot_Pck</th>
                    <th>Tot_Bal</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {dspData.length === 0 ||
                (!ctx.openCheck && !ctx.procesCheck) ? (
                  <tr>
                    <td colSpan="14" className="text-center">
                      <h6>No data found</h6>
                    </td>
                  </tr>
                ) : (
                  dspData &&
                  dspData.map((po, index) => {
                    const checkboxValue = po.id + "*" + po.PO;
                    return (
                      // <tr key={po.STY + index} className="table-active"></tr>
                      <tr key={po.id} className="table-active">
                        {/* {isFilter && <th className="title-head">#PO_qty</th>} */}
                        {userData.user == 1 && (
                          <td
                            className="px-0 d-flex justify-content-center"
                            style={{ border: "none" }}
                          >
                            {index + 1}
                            <Checkbox
                              checked={ctx.selectedCheckboxes.includes(
                                "" + po.id
                              )}
                              selectedPo={ctx.selectedPo}
                              handleCheckboxChange={handleCheckboxChange}
                              value={checkboxValue}
                            />
                          </td>
                        )}

                        <td>{po?.FACT}</td>
                        <td>{po?.SUP}</td>
                        <td>{po?.AGNT}</td>
                        <td className="fw-bold">
                          {/* <a
                        href={`/orders?PO=${po?.PO}&STY=${id.STY}&LOT=${id.LOT}&DIM=${id.DIM}&CLR=${id.CLR}&FACT=${id.FACT}&SUP=${id.SUP}`}
                      > */}
                          {po?.PO}
                          {/* </a>{" "} */}
                        </td>
                        <td>{po?.FCUS}</td>
                        <td>{po?.SEA}</td>
                        <td>{po?.STY}</td>
                        <td>{po?.LOT}</td>
                        <td>{po?.CLR}</td>
                        <td>{po?.DIM}</td>
                        <td className="qty_alignment">{po?.TOT_QTY}</td>
                        <td className="qty_alignment">
                          {po?.packedQuantity?.TOT_QTY}
                        </td>
                        <td className="qty_alignment">
                          {po?.balanceQuantity?.TOT_QTY * -1}
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          )
        )}
        {!isLoading && initialFetchDone && (
          <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {/* {!!ctx.pageCount && (
          <ReactPaginate
            // style={{ marginTop: "-20px", marginBottom: "50PX" }}
            disableInitialCallback={true}
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={ctx.pageCount}
            forcePage={ctx.currentPage}
            // initialPage={ctx.currentPage}

            previousLabel="< prev"
            renderOnZeroPageCount={null}
            marginPagesDisplayed={2}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
        )} */}
      </div>
    </>
  );
};

export default DisplayPurchase;
