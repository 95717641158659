import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../components/header";
import { useContext, useEffect, useState } from "react";
import Context from "../../../context/context";
import toast from "react-hot-toast";
import ErrosToast from "../../../components/ErrosToast";

const EditUser = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [barcodeRanges, setBarcodeRanges] = useState([]);
  const [selectedBR, setSBR] = useState("select");
  const [showPassword, setShowPassword] = useState(false);

  const [factNames, setFactNames] = useState([]);
  const [filteredFactSuggestions, setFilteredFactSuggestions] =
    useState(factNames);

  const [supNames, setSupNames] = useState([]);
  const [filteredSupSuggestions, setFilteredSupSuggestions] =
    useState(supNames);

  const [agentNames, setAgentNames] = useState([]);
  const [filteredAgentSuggestions, setFilteredAgentSuggestions] =
    useState(agentNames);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const ctx = useContext(Context);
  const { blankUserState } = useContext(Context);

  useEffect(() => {
    axios.get("/po/getPoSummary").then((result) => {
      const uniqueFacts = [];
      const uniqueSup = [];
      const uniqueAgents = [];
      result.data.purchaseOrders.filter((data) => {
        if (!uniqueFacts.includes(data.FACT) && data.FACT !== null) {
          uniqueFacts.push(data.FACT);
        }
        if (!uniqueSup.includes(data.SUP) && data.SUP !== null) {
          uniqueSup.push(data.SUP);
        }
        if (!uniqueAgents.includes(data.AGNT) && data.AGNT !== null) {
          uniqueAgents.push(data.AGNT);
        }
      });
      setFactNames(uniqueFacts);
      setSupNames(uniqueSup);
      setAgentNames(uniqueAgents);
      //setFilteredFactSuggestions(factNames);
    });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/admin");
    }

    if (id) {
      axios
        .get(process.env.REACT_APP_API_LINK + "/api/v1/admin/getUser?id=" + id)
        .then((res) => {
          console.log("data from getUser", res);
          setUserData({
            id: res.data.user.id, // for comparing the current user and updated user in modifyUser.
            username: res.data.user.username,
            password: res.data.user.password,
            supplier: res.data.user.supplier,
            factory: res.data.user.factory,
            barcodeRangeId: res.data.user.barcodeRangeId,
            selectedBR: res.data.user.barcodeRangeId,
            user: res.data.user.user,
            company: res.data.user.company,
            agent: res.data.user.agent,
            admin: res.data.user.admin,
            dsp: res.data.user.dsp,
            boxDetails: res.data.user.boxDetails,
            shipInfo: res.data.user.shipInfo,
            entry: res.data.user.entry,
            entryModification: res.data.user.entryModification,
            csv: res.data.user.csv,
            orders: res.data.user.orders,
            createUser: res.data.user.createUser,
            modifyUser: res.data.user.modifyUser,
            loadPO: res.data.user.loadPO,
            boxType: res.data.user.boxType,
            rangeCode: res.data.user.rangeCode,
            // others: res.data.user.others,
            barcodePdf: res.data.user.barcodePdf,
            exFactory: res.data.user.exFactory,
            shipmentLoading: res.data.user.shipmentLoading,
            defaultOvership: res.data.user.defaultOvership,
            overshipAccess: res.data.user.overshipAccess,
            etdAccess: res.data.user.etdAccess,
            reports: res.data.user.reports,
            deletePoEnteriesAccess: res.data.user.deletePoEnteriesAccess,
          });
          setSBR(res.data.user.barcodeRangeId);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setUserData(blankUserState);
    }

    axios
      .get(process.env.REACT_APP_API_LINK + "/api/v1/admin/getAllBarcodeRanges")
      .then(({ data }) => {
        // console.log("barcodessssss", data.barcodeRanges);
        setBarcodeRanges(data.barcodeRanges);
        // setSBR();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [userData, setUserData] = useState(() => {
    const savedData = JSON.parse(localStorage.getItem("modifyData"));
    if (savedData !== null) {
      return savedData;
    }
    return blankUserState;
  });
  console.log("user data in modify", userData);

  const UpdateUser = (e) => {
    e.preventDefault();
    if (userData === "") {
      toast.remove();
      return ErrosToast("Please Fill the form");
    }

    if (
      userData.username === "" &&
      userData.password === "" &&
      userData.company === ""
    ) {
      toast.remove();
      return ErrosToast("Please Enter Highlighted Field");
    }

    if (userData.username === "") {
      toast.remove();
      return ErrosToast("Please Provide User Name");
    }

    if (userData.password === "") {
      toast.remove();
      return ErrosToast("Please Provide Password");
    }
    if (userData.company === "") {
      toast.remove();
      return ErrosToast("Please Give Company Name");
    }

    if (userData.entry && selectedBR === "select") {
      toast.remove();
      return ErrosToast("Barcode range should be there.");
    }
    if (!userData.admin && !userData.user) {
      toast.remove();
      return ErrosToast("Please selct any of role User or Admin");
    }
    if (selectedBR === "select" && !userData.admin) {
      toast.remove();
      return ErrosToast("Select Barcode Range");
    }

    axios
      .post(process.env.REACT_APP_API_LINK + "/api/v1/admin/updateUser", {
        userData,
        id,
      })
      .then((res) => {
        if (ctx.userData.id === parseInt(id)) {
          //this is the condition for updating the userData if the same user is updated in which
          ctx.setUserData(res.data.userData); // we are logged in.
        } else {
          // if we are not updating the user in which we are not logged in
          const updatedUserLists = ctx.userlist.map((user) =>
            user.id === id ? res.data.userData : user
          );
          ctx.setUserlist(updatedUserLists);
        }
        toast.remove();
        toast.success("User Data updated successfully!");
        localStorage.removeItem("modifyData");
        setUserData(blankUserState);
        navigate("/ModifyUser");
      });
  };

  // console.log("user data from edit user", userData);
  // console.log("context userdata", ctx.userData);
  // console.log("user lists", ctx.userlist);
  // console.log('user data id', ctx.userData.id)
  // console.log('id from url', id)
  // console.log('compare 2 numbers', ctx.userData.id===parseInt(id))

  useEffect(() => {
    // for saving the data if we switch to another tab
    const savedData = JSON.parse(localStorage.getItem("modifyData"));
    if (savedData) {
      setUserData(savedData);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("modifyData", JSON.stringify(userData));
  }, [userData]);

  const handleDelete = (e) => {
    e.preventDefault();
    navigate("/ModifyUser");
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setUserData({
      ...userData,
      [e.target.name]: userInput.trim().toUpperCase(),
    });
    if (e.target.name === "factory") {
      const filterd = factNames.filter((fact, index) =>
        fact.toLowerCase().startsWith(userInput.toLowerCase())
      );
      setFilteredFactSuggestions(filterd);
    }
    if (e.target.name === "supplier") {
      const filterd = supNames.filter((sup, index) =>
        sup.toLowerCase().startsWith(userInput.toLowerCase())
      );
      setFilteredSupSuggestions(filterd);
    }
    if (e.target.name === "agent") {
      const filterd = agentNames.filter((agent, index) =>
        agent.toLowerCase().startsWith(userInput.toLowerCase())
      );
      setFilteredAgentSuggestions(filterd);
    }

    //console.log(userInput)
  };

  const handleSuggestionSelect = (selectSuggestion) => {
    setFilteredFactSuggestions([]);
  };

  return (
    <div className="full-page">
      <div className="sticky-top">
        <Header />
      </div>

      <div className="container my-4 layout">
        <form
          className="row g-3"
          // onSubmit={!id ? createUser : UpdateUser}
          autoComplete="off"
        >
          <div className="Auth-form-content">
            {/* <h3 className="Auth-form-title">Modify User</h3> */}

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mt-3">
                  <label>Username*</label>
                  <input
                    type="text"
                    required
                    className="form-control mt-1 "
                    placeholder="Enter Username"
                    name="username"
                    maxLength={20}
                    value={userData.username}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        [e.target.name]: e.target.value.trim(),
                      })
                    }
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group mt-3">
                  <label>Password*</label>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control mt-1"
                      placeholder="Enter password"
                      name="password"
                      required
                      maxLength={10}
                      value={userData.password}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          [e.target.name]: e.target.value.trim(),
                        })
                      }
                      autoComplete="off"
                    />

                    <div
                      style={{
                        position: "absolute",
                        right: "10px",
                        width: "13%",
                        height: "70%",
                        border: "none",
                        background: "transparent",
                      }}
                      className="btn btn-sm btn-secondary mt-1"
                      onClick={handleTogglePassword}
                    >
                      {showPassword ? (
                        <i
                          className="fas fa-eye-slash"
                          style={{ color: "black" }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-eye"
                          style={{ color: "black" }}
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mt-3">
                  <label>Company Name*</label>
                  <input
                    type="text"
                    className="form-control mt-1 "
                    placeholder="Enter Company"
                    name="company"
                    maxLength={10}
                    required
                    value={userData.company}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        [e.target.name]: e.target.value.trim(),
                      })
                    }
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mt-3">
                  <label>Factory</label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    placeholder="Enter Factory"
                    name="factory"
                    maxLength={5}
                    value={userData.factory}
                    onChange={handleInputChange}
                    list="FactSuggestions"
                    autoComplete="off"
                  />
                  <datalist id="FactSuggestions">
                    {filteredFactSuggestions.map((fact, index) => (
                      <option
                        key={index}
                        onClick={() => handleSuggestionSelect(fact)}
                        value={fact}
                      >
                        {fact}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mt-3">
                  <label>Supplier</label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    placeholder="Enter Supplier"
                    name="supplier"
                    maxLength={5}
                    value={userData.supplier}
                    onChange={handleInputChange}
                    list="SupSuggestions"
                    autoComplete="off"
                  />
                  <datalist id="SupSuggestions">
                    {filteredSupSuggestions.map((sup, index) => (
                      <option
                        key={index}
                        onClick={() => handleSuggestionSelect(sup)}
                        value={sup}
                      >
                        {sup}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mt-3">
                  <label>Agent</label>
                  <input
                    type="text"
                    className="form-control mt-1"
                    placeholder="Enter Agent"
                    name="agent"
                    maxLength={5}
                    value={userData.agent}
                    onChange={handleInputChange}
                    list="agentSuggestions"
                    autoComplete="off"
                  />
                  <datalist id="agentSuggestions">
                    {filteredAgentSuggestions.map((agent, index) => (
                      <option
                        key={index}
                        onClick={() => handleSuggestionSelect(agent)}
                        value={agent}
                      >
                        {agent}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
            </div>

            <div className="form-group mt-3">
              <label>Barcode Range:</label>

              <select
                className="form-select-md ms-2"
                id="dropdown"
                size="sm"
                // defaultValue={barcodeRanges[0].id}
                style={{ border: "2px solid #000", width: "250px" }}
                value={selectedBR}
                onChange={(e) => {
                  setSBR(e.target.value);
                  setUserData({
                    ...userData,
                    barcodeRangeId:
                      e.target.value === "select" ? null : e.target.value,
                    //selectedBR: e.target.value
                    //selectedBR: ba === "select" ? null : selectedBR
                  });
                }}
              >
                <option key={"select"} value="select">
                  Select
                </option>
                {barcodeRanges &&
                  barcodeRanges.map((bt, i) => (
                    // console.log('bt', bt)
                    <option
                      key={bt.id}
                      value={bt.id}
                      style={{
                        color: bt.usernames.length > 0 ? "brown" : "",
                      }}
                    >
                      {selectedBR === bt.id ? (
                        <div>{bt.id}</div>
                      ) : (
                        <div>{bt.id + ": " + bt.usernames}</div>
                      )}
                    </option>
                  ))}
              </select>
            </div>

            <div className="my-3 d-flex align-items-center">
              <div className="col-md-3 d-flex align-items-center">
                <label>Percentage overship:</label>
                <input
                  type="text"
                  className="form-control-md"
                  style={{ width: "30px", borderRadius: "4px" }}
                  name="defaultOvership"
                  value={userData.defaultOvership}
                  onChange={handleInputChange}
                  placeholder="%"
                  maxLength={2}
                  autoComplete="off"
                />
              </div>

              <div className="col-md-2 ms-3">
                <input
                  id="etdCheckbox"
                  type="checkbox"
                  className="me-2 form-check-input"
                  name="etdAccess"
                  value={userData.etdAccess}
                  checked={userData.etdAccess}
                  onChange={(e) => {
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    });
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="etdCheckbox" // Added htmlFor to associate label with checkbox
                  onClick={(e) => e.preventDefault()}
                >
                  ETD
                </label>
              </div>

              <div className="col-md-3">
                <input
                  id="overshipAccess"
                  type="checkbox"
                  className="me-2 form-check-input"
                  name="overshipAccess"
                  value={userData.overshipAccess}
                  checked={userData.overshipAccess}
                  onChange={(e) => {
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    });
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="poOvershipCheckbox" // Added htmlFor to associate label with checkbox
                  onClick={(e) => e.preventDefault()}
                >
                  PO overship
                </label>
              </div>
              <div className="col-md-4 d-flex justify-content-center">
                <input
                  id="deletePoEnteriesAccess"
                  type="checkbox"
                  className="me-2 form-check-input"
                  name="deletePoEnteriesAccess"
                  value={userData.deletePoEnteriesAccess}
                  checked={userData.deletePoEnteriesAccess}
                  onChange={(e) => {
                    setUserData((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    });
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="deletePoEnteriesCheckbox" // Added htmlFor to associate label with checkbox
                  onClick={(e) => e.preventDefault()}
                >
                  Delete poEntries
                </label>
              </div>
            </div>
            {/* ctrl x */}

            <div className="row">
              <div className="col-md-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    name="admin"
                    value={userData.admin}
                    checked={userData.admin}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          [e.target.name]: e.target.checked,
                          dsp: prev.user || !prev.admin || e.target.checked,
                          loadPO: prev.user || !prev.admin || e.target.checked,
                          orders: prev.user || !prev.admin || e.target.checked,
                          boxDetails:
                            prev.user || !prev.admin || e.target.checked,
                          // others: prev.user || !prev.admin || e.target.checked,
                          reports: prev.user || !prev.admin || e.target.checked,
                          csv: prev.user || !prev.admin || e.target.checked,
                          createUser: e.target.checked,
                          modifyUser: e.target.checked,
                          rangeCode: e.target.checked,
                          // orders: e.target.checked,
                          shipmentLoading:
                            !prev.user || prev.admin || e.target.checked,
                          exFactory:
                            !prev.user || prev.admin || e.target.checked,
                          barcodePdf:
                            !prev.user || prev.admin || e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    Admin
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    name="user"
                    value={userData.user}
                    checked={userData.user}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          [e.target.name]: e.target.checked,
                          dsp: !prev.user || prev.admin || e.target.checked,
                          //dsp: prev.dsp || e.target.checked,
                          loadPO: !prev.user || prev.admin || e.target.checked,
                          orders: !prev.user || prev.admin || e.target.checked,
                          boxDetails:
                            !prev.user || prev.admin || e.target.checked,
                          // others: !prev.user || prev.admin || e.target.checked,
                          reports: !prev.user || prev.admin || e.target.checked,
                          shipInfo: e.target.checked,
                          entry: e.target.checked,
                          entryModification: e.target.checked,
                          csv: !prev.user || prev.admin || e.target.checked,
                          // orders: e.target.checked,
                          boxType: e.target.checked,
                          shipmentLoading:
                            !prev.user || prev.admin || e.target.checked,
                          exFactory:
                            !prev.user || prev.admin || e.target.checked,
                          barcodePdf:
                            !prev.user || prev.admin || e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    User
                  </label>
                </div>
              </div>
              <div className="col-lg-4"></div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    placeholder="Display Purchase Orders"
                    name="dsp"
                    value={userData.dsp}
                    checked={userData.dsp}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          //dsp: prev.admin || prev.user || e.target.checked,
                          [e.target.name]: e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    Display Purchase
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    placeholder="Orders"
                    name="orders"
                    value={userData.orders}
                    checked={userData.orders}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          [e.target.name]: e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    PO Summary
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    placeholder="Box Details"
                    name="boxDetails"
                    value={userData.boxDetails}
                    checked={userData.boxDetails}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          [e.target.name]: e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    Box Details
                  </label>
                </div>
              </div>

              <div className="col-lg-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    placeholder="Ship Info"
                    name="shipInfo"
                    value={userData.shipInfo}
                    checked={userData.shipInfo}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          [e.target.name]: e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    Ship Details
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    placeholder="CSV"
                    name="csv"
                    value={userData.csv}
                    checked={userData.csv}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          [e.target.name]: e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    Transfer Data
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    placeholder="Entry"
                    name="entry"
                    value={userData.entry}
                    checked={userData.entry}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          [e.target.name]: e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    Box Entries
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    placeholder="Create User"
                    name="createUser"
                    value={userData.createUser}
                    checked={userData.createUser}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          [e.target.name]: e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    Create User
                  </label>
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    placeholder="Entry Modification"
                    name="entryModification"
                    value={userData.entryModification}
                    checked={userData.entryModification}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          [e.target.name]: e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    Modify Entry
                  </label>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    placeholder="modifyUser"
                    name="modifyUser"
                    value={userData.modifyUser}
                    checked={userData.modifyUser}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          [e.target.name]: e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    Modify User
                  </label>
                </div>
              </div>

              <div className="col-lg-6">
                <div className=" form-check-lg">
                  <input
                    id="largeCheckbox"
                    type="checkbox"
                    className="flex-lg-fil me-2 form-check-input"
                    placeholder="BoxType"
                    name="boxType"
                    value={userData.boxType}
                    checked={userData.boxType}
                    onChange={(e) =>
                      setUserData((prev) => {
                        return {
                          ...prev,
                          [e.target.name]: e.target.checked,
                        };
                      })
                    }
                  />
                  <label
                    className="form-check-label"
                    onClick={(e) => e.preventDefault()}
                  >
                    Box Type
                  </label>
                </div>
              </div>
            </div>

            <div className=" form-check-lg">
              <input
                id="largeCheckbox"
                type="checkbox"
                className="flex-lg-fil me-2 form-check-input"
                placeholder="loadPO"
                name="loadPO"
                value={userData.loadPO}
                checked={userData.loadPO}
                onChange={(e) =>
                  setUserData((prev) => {
                    return { ...prev, [e.target.name]: e.target.checked };
                  })
                }
              />
              <label
                className="form-check-label"
                onClick={(e) => e.preventDefault()}
              >
                Load Data
              </label>
            </div>
            <div className="col-md-12">
              <input
                id="largeCheckbox"
                type="checkbox"
                className="flex-lg-fil me-2 form-check-input"
                placeholder="ShipmentLoading"
                name="shipmentLoading"
                value={userData.shipmentLoading}
                checked={userData.shipmentLoading}
                onChange={(e) =>
                  setUserData((prev) => {
                    return { ...prev, [e.target.name]: e.target.checked };
                  })
                }
              />
              <label
                className="form-check-label"
                onClick={(e) => e.preventDefault()}
              >
                ShipmentLoading
              </label>
            </div>
            <div className="col-md-12">
              <input
                id="largeCheckbox"
                type="checkbox"
                className="flex-lg-fil me-2 form-check-input"
                placeholder="BarCodePdf"
                name="barcodePdf"
                value={userData.barcodePdf}
                checked={userData.barcodePdf}
                onChange={(e) =>
                  setUserData((prev) => {
                    return { ...prev, [e.target.name]: e.target.checked };
                  })
                }
              />
              <label
                className="form-check-label"
                onClick={(e) => e.preventDefault()}
              >
                Barcodepdf
              </label>
            </div>
            <div className="col-md-12">
              <input
                id="largeCheckbox"
                type="checkbox"
                className="flex-lg-fil me-2 form-check-input"
                placeholder="ExFactory"
                name="exFactory"
                value={userData.exFactory}
                checked={userData.exFactory}
                onChange={(e) =>
                  setUserData((prev) => {
                    return { ...prev, [e.target.name]: e.target.checked };
                  })
                }
              />
              <label
                className="form-check-label"
                onClick={(e) => e.preventDefault()}
              >
                ExFactory
              </label>
            </div>
            <div className=" form-check-lg">
              <input
                id="largeCheckbox"
                type="checkbox"
                className="flex-lg-fil me-2 form-check-input"
                placeholder="RangeCode"
                name="rangeCode"
                value={userData.rangeCode}
                checked={userData.rangeCode}
                onChange={(e) =>
                  setUserData((prev) => {
                    return { ...prev, [e.target.name]: e.target.checked };
                  })
                }
              />
              <label
                className="form-check-label"
                onClick={(e) => e.preventDefault()}
              >
                Range Code
              </label>
            </div>
            {/* <div className=" form-check-lg">
              <input
                id="largeCheckbox"
                type="checkbox"
                className="flex-lg-fil me-2 form-check-input"
                placeholder="Others"
                name="others"
                value={userData.others}
                checked={userData.others}
                onChange={(e) =>
                  setUserData((prev) => {
                    return { ...prev, [e.target.name]: e.target.checked };
                  })
                }
              />
              <label
                className="form-check-label"
                onClick={(e) => e.preventDefault()}
              >
                Others
              </label>
            </div> */}

            <div className="">
              <input
                id="largeCheckbox"
                type="checkbox"
                className="flex-lg-fil me-2 form-check-input"
                placeholder="reports"
                name="reports"
                value={userData.reports}
                checked={userData.reports}
                onChange={(e) =>
                  setUserData((prev) => {
                    return { ...prev, [e.target.name]: e.target.checked };
                  })
                }
              />
              <label
                className="form-check-label"
                onClick={(e) => e.preventDefault()}
              >
                Reports
              </label>
            </div>

            <div className="row mt-4">
              <div className="col-lg-12 d-flex justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary fw-bold me-2"
                  onClick={UpdateUser}
                >
                  Save
                </button>
                <button
                  className="ms-4 btn btn-danger fw-bold"
                  onClick={handleDelete}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditUser;
