import React, { useCallback, useContext } from "react";
import _, { debounce } from "underscore";

import Header from "../../components/header";
import { Col, Form, Row, Spinner, Container } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Context from "../../context/context";
import toast from "react-hot-toast";
import ErrosToast from "../../components/ErrosToast";
const MAX_RETRIES = 10;

const BarcodePdf = () => {
  const [entryByPOdata, setEntryByPOdata] = useState([]); // to store the data of api /entry/getAllByPO
  const [isLoading, setIsLoading] = useState(false); //
  const [sentCheckBox, setSentCheckBox] = useState(false);
  const [unsentCheckBox, setUnsentCheckBox] = useState(true);

  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);

  const [poSearch, setPoSearch] = useState("");

  const ctx = useContext(Context);
  const name = ctx.userData.username;
  // console.log("ctx==>>>line 30", name);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const PO = queryParams.get("PO");

  console.log("newww", PO);
  console.log("rangeApi", ctx.rangeApi);

  const fetchData = async (
    sentCheckBox,
    unsentCheckBox,
    pageNo,
    limit,
    poSearch
  ) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/entry/getAllByPO?sent=${sentCheckBox}&unsent=${unsentCheckBox}&pageNo=${pageNo}&docNo=${limit}&po=${poSearch}`
      );
      // console.log("ressss", res.data.entries[0].PO);
      setEntryByPOdata(res.data.entries);
      // setIsLoading(false);
      setPage(pageNo);
      setInitialFetchDone(true);
      if (res.data.entries.length < limit) {
        setHasMore(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const OnChangeHandleFilter = useCallback(
    // for filter by every letter
    debounce(
      (poSearch, sentCheckBox, unsentCheckBox) =>
        fetchData(sentCheckBox, unsentCheckBox, 0, 500, poSearch),
      1000,
      false
    ),
    []
  );

  useEffect(() => {
    // fetchData(0, 30)
    console.log("am i called?");
    if (!initialFetchDone) {
      fetchData(sentCheckBox, unsentCheckBox, 0, 500, poSearch);
      // Set initialFetchDone to true after initial fetch
      return;
    }

    const tableContainer = document.querySelector(".table-container");
    if (!tableContainer) return;

    const handleScroll = () => {
      const table = tableContainer.querySelector(".table");

      if (!table) return;

      const tableBody = table.querySelector("tbody");

      // Add this check
      if (!tableBody) return;

      const lastRow = tableBody.querySelector("tr:last-child");

      if (lastRow) {
        console.log("boxapi length", entryByPOdata.length);
        const lastRowOffset = lastRow.offsetTop + lastRow.clientHeight;
        const containerOffset =
          tableContainer.scrollTop + tableContainer.clientHeight;
        const bottomOffset = 20;

        if (
          containerOffset > lastRowOffset - bottomOffset &&
          hasMore &&
          !isLoading
        ) {
          fetchData(
            sentCheckBox,
            unsentCheckBox,
            0,
            30 * ((entryByPOdata.length + 30) / 30),
            poSearch
          );
        }
      }
    };

    tableContainer.addEventListener("scroll", handleScroll);

    return () => {
      tableContainer.removeEventListener("scroll", handleScroll);
    };
  }, [sentCheckBox, unsentCheckBox, page, isLoading]);

  // const generateBarcodes = async (PO) => {
  //   // console.log("clicked");
  //   await axios
  //     .post("/barcode/generate", {
  //       PO: PO,
  //     })
  //     .then((res) => {
  //       // if (res.status === 200) {
  //       //   navigate("/exFactory");
  //       // }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   navigate("/exFactory");
  // };

  const BarcodeButton = ({ PO, BoxGroup ,user_to_use=null }) => {
    const [generating, setGenerating] = useState(false);
    async function generateBarcode(retries = 0) {
      setGenerating(true);
      const checkBoxGroup =
        BoxGroup === null || BoxGroup === undefined || BoxGroup === ""
          ? ""
          : BoxGroup;
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_LINK}/api/v1/csv/generateBarcodes?PO=${PO}&BoxGroup=${checkBoxGroup}&user_to_use=${user_to_use}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        window.location.assign(
          `${process.env.REACT_APP_API_LINK}/api/v1/csv/download?oneTimeToken=${res.data.downloadToken}`
        );

        try {
          const profileRes = await axios.get("/auth/getProfile");
          ctx.setUserData(profileRes.data.user);
        } catch (err) {
          console.log(err);
        }

        toast.remove();
        toast.success("Barcode range printed successfully");
        setGenerating(false);
        setSentCheckBox(true);
        setUnsentCheckBox(false);
        setHasMore(true);
        fetchData(true, false, 0, 500, poSearch);
        console.log("ctx po data length", ctx.poData.length);
        if (ctx.poData.length > 0) {
          const lockedData = await axios.post(
            process.env.REACT_APP_API_LINK +
              "/api/v1/po/getLockedPurchaseOrders",
            {
              POIDs: ctx.selectedCheckboxes,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          ctx.setLastBCGeneratedSNo(lockedData.data.lastBCGeneratedSNo);
          ctx.setLastTo(lockedData.data.lastValueOfTo);
        }
      } catch (err) {
        const status = _.get(err, ["response", "status"], 0);

        if (status === 429 && retries < MAX_RETRIES) {
          console.log(
            `Too many requests. Retrying in 5 seconds... (${
              retries + 1
            }/${MAX_RETRIES})`
          );
          setTimeout(() => generateBarcode(retries + 1), 5000);
        } else {
          setGenerating(false);
          console.log(err);
          toast.remove();
          ErrosToast(_.get(err, "response.data.message", "An error occurred"));
        }
      }
    }
    console.log("po data", ctx.poSummary);
    const DownloadBarcodePdf = async () => {
      // if (ctx.userData.leftBarcode <= 1000) {
      //   alert("Left Nve Range is less than 1000, Do you want to continue");
      // }
      // const checkPOqty = ctx.poSummary.find((poData) => poData.PO === PO)
      // console.log('check po qwtu', checkPOqty)

      let alertCheck = false;
      const hasBoxGroup = BoxGroup && BoxGroup.trim();

      if (!hasBoxGroup) {
        alertCheck = window.confirm(
          `If you generate a barcode PDF for ${PO}, the next box group's sequence will start from 1. Do you want to proceed?`
        );

        // Proceed only if the user confirms
        if (alertCheck) {
          await generateBarcode();
        } else {
          setGenerating(false);
        }
      } else {
        await generateBarcode();
      }
    };

    return (
      <button
        className="btn btn-none "
        onClick={() => {
          DownloadBarcodePdf();
        }}
        disabled={generating}
      >
        {generating ? (
          <Spinner size="lg" />
        ) : (
          <img
            width="37"
            height="37"
            src="https://img.icons8.com/cotton/64/barcode-scanner--v3.png"
            alt="barcode-scanner--v3"
          />
        )}
      </button>
    );
  };
  const ExcelButton = ({ PO, STY, BoxGroup }) => {
    const [generating, setGenerating] = useState(false);
  
    const DownloadExcel = () => {
      setGenerating(true);
      axios
        .get(`/csv/poSummaryExcels`, {
          params: {
            PO,
            STY,
            BoxGroup,
            timezone: localStorage.getItem("timezone"),
          },
        })
        .then((res) => {
          // setTimeout(() => {
          //   window.location.replace(
          //     process.env.REACT_APP_API_LINK +
          //     "/api/v1/csv/download?oneTimeToken=" +
          //     res.data.downloadTokens[0]
          //   );
          // }, 1000);
          window.location.replace(
            process.env.REACT_APP_API_LINK +
              "/api/v1/csv/download?oneTimeToken=" +
              res.data.downloadTokens[0]
          );
          setGenerating(false);
        })
        .catch((err) => {
          console.log(err);
          setGenerating(false);
          alert("Something went wrong!");
        });
    };

    return (
      <button
        className="btn btn-none "
        onClick={() => {
          DownloadExcel();
        }}
        disabled={generating}
      >
        {generating ? (
          <Spinner size="lg" />
        ) : (
          <img
            width="37"
            height="37"
            src="https://img.icons8.com/color/48/export-excel.png"
            alt="barcode-scanner--v4"
          />
        )}
      </button>
    );
  };

  return (
    <>
      <div className="fixed-top">
        <Header />
        <div
          className=" d-flex"
          style={{
            // border: "2px solid blue",
            background: "#ffff",
            padding: "1% 0% 1% 2%",
            width: "100%",
          }}
        >
          <form>
            <div
              class="container"
              style={{
                display: "flex",
                width: "137%",
                // border: "2px solid red",
                justifyContent: "space-between",
                padding: "1% 6%",
              }}
            >
              <div class="row">
                <div
                  class="col"
                  lg={name === "admin" ? 3 : 2}
                  style={{
                    // border: "2px solid green",
                    // height: "9vh",
                    paddingTop: "8%",
                  }}
                >
                  <Form.Check
                    type="checkbox"
                    value={unsentCheckBox}
                    checked={unsentCheckBox}
                    onChange={(e) => {
                      setUnsentCheckBox((prev) => !prev);
                      setHasMore(true);
                      fetchData(
                        sentCheckBox,
                        e.target.checked,
                        0,
                        500,
                        poSearch
                      );
                    }}
                    label="Open"
                  />
                </div>
              </div>
              <div class="row">
                <div
                  class="col"
                  lg={name === "admin" ? 5 : 3}
                  style={{
                    // border: "2px solid blue",
                    // height: "9vh",
                    paddingTop: "5%",
                  }}
                >
                  <Form.Check
                    type="checkbox"
                    checked={sentCheckBox}
                    value={sentCheckBox}
                    onChange={(e) => {
                      setSentCheckBox((prev) => !prev);
                      setHasMore(true);
                      fetchData(
                        e.target.checked,
                        unsentCheckBox,
                        0,
                        500,
                        poSearch
                      );
                    }}
                    className="ms-3"
                    label="Ex Factory"
                  />
                </div>
              </div>
              <div class="row">
                <div
                  class="col"
                  lg={name === "admin" ? 0 : 4}
                  className="mt-2"
                  style={{
                    display: name === "admin" ? "none" : "show",
                    // border: "2px solid green",
                  }}
                >
                  {ctx.userData.admin === 0 && unsentCheckBox && (
                    <div className="ms-4">{`Left NVE Range : ${ctx.userData.leftBarcode}`}</div>
                  )}
                </div>
              </div>
              <div class="row">
                <div
                  div
                  class="col"
                  lg={name === "admin" ? 5 : 3}
                  style={{
                    width: "25%",
                    // border: "2px solid black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "5%",
                  }}
                >
                  <div className="fs-6 me-2" style={{ width: "auto" }}>
                    PO#
                  </div>
                  <Form.Control
                    type="text"
                    name="po"
                    maxLength={6}
                    style={{ width: "140px" }}
                    value={poSearch}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault(); // Prevent form submission on Enter
                      }
                    }}
                    onChange={(e) => {
                      setPoSearch(
                        e.target.value.replace(/\D/g, "").toUpperCase()
                      );
                      OnChangeHandleFilter(
                        e.target.value.replace(/\D/g, "").toUpperCase(),
                        sentCheckBox,
                        unsentCheckBox
                      );
                    }}
                    placeholder="PO"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        className="mx-4 tableFixHead table-container"
        style={{ marginTop: "165px", marginBottom: "40px" }}
      >
        {isLoading && !initialFetchDone ? (
          <Loader />
        ) : (
          <div className="table-responsive mx-4 tableFixHead table-container">
            <table className="table table-bordered table-hover table-sm mt-4 table-header-for-all">
              <thead>
                <tr className="table-warning">
                  <th>S.No.</th>
                  <th>PO#</th>
                  <th>Box Group</th>
                  <th>Style</th>

                  <th>Factory</th>
                  <th>User</th>
                  <th>Number Of Boxes</th>
                  {/* {ctx.userData.admin === 0 && <th>GenerateBarcode</th>} */}
                  {ctx.userData.admin ===1 ? <th>Print PDF</th> : <th>Generate/Print</th>}

                  {/* <th>Print PDF</th> */}
                  <th>Excel</th>
                </tr>
              </thead>
              {entryByPOdata.length > 0 ? (
                <tbody>
                  {entryByPOdata.map((el, i) => {
                    console.log("entry by po data", el);
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        {/* <Link to={`/exFactory?PO=${el.PO}`}>{el.PO}</Link> */}
                        <td>{el.PO}</td>
                        <td>{el.BoxGroup}</td>
                        <td>{el["entry.STY"]}</td>

                        <td>{el["entry.FACT"]}</td>
                        <td>{el["entry.username"]}</td>
                        <td>{el.noOfBoxes}</td>
                        {/* {ctx.userData.admin === 0 && (

                      <td>
                        <button
                          onClick={() => generateBarcodes(el.PO)}
                          type="button"
                          className="btn btn-dark text-center"
                          style={{ width: "150px" }}
                        >
                          GenerateRange
                        </button>
                      </td>
                    )} */}
                        {ctx.userData.admin === 0 ? (
                          <td>
                        <BarcodeButton
                          key={el.PO}
                          PO={el.PO}
                          BoxGroup={el.BoxGroup}
                          
                        />
                      </td>
                        )
                        :(el.BoxGroup ?
                          <td>
                        <BarcodeButton
                          key={el.PO}
                          PO={el.PO}
                          BoxGroup={el.BoxGroup}
                          user_to_use={el["entry.username"]}
                        />
                        </td>
                        :
                        <td>
                          Barcode isn’t generated yet
                        </td>
                      )}
                        <td>
                          <ExcelButton
                            key={el.PO}
                            PO={el.PO}
                            STY={el["entry.STY"]}
                            BoxGroup={el.BoxGroup}
                          />
                          {/* <button className="btn btn-none">
                        <img
                          width="37"
                          height="37"
                          src="https://img.icons8.com/color/48/export-excel.png"
                          alt="export-excel"
                        />
                      </button> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <tr>
                  <td
                    colSpan="10"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <h5>NO DATA FOUND</h5>
                  </td>
                </tr>
              )}
            </table>
            {/* {!isLoading && !hasMore && <div>No more data</div>} */}
            {isLoading && (
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BarcodePdf;
