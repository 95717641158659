import { useContext, useEffect, useState } from "react";

import { Form, Button, Container, Row, Col } from "react-bootstrap";
import Context from "../../context/context";
import axios from "axios";

const Weights = ({ weightData, setWeightData }) => {
  const ctx = useContext(Context);

  //  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  // const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  // const handleUserInput = (e) => {
  //   e.preventDefault();
  //   setWeightData({ ...weightData, [e.target.name]: e.target.value });
  // };
  const [boxType, setBoxType] = useState([]);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_LINK + "/api/v1/admin/getAllBoxMasters"

        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("token")}`,
        //   },
        // }
      )
      .then((res) => {
        //console.log('result hai kya',res);
        setBoxType(res.data.boxMasters);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleUpdate = () => {
    axios
      .post(
        process.env.REACT_APP_API_LINK + "/api/v1/entry/update",
        {
          boxData: ctx.boxData,
          weightData,
          seqId: weightData.id,
          p: "h",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        alert("success");
      })
      .catch(() => {
        console.log("errr");
      });
  };
  return (
    <>
      <div className="mx-4 mt-2">
        <Form>
          <Row>
            <Col>
              <Form.Group controlId="input1">
                <Form.Label className="bold-label">SequenceId:</Form.Label>
                <Form.Control
                  type="text"
                  name="SequenceId"
                  autoComplete="off"
                  value={weightData.id}
                  placeholder="SequenceId"
                  disabled
                  // onChange={handleUserInput}
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="input7">
                <Form.Label className="bold-label">Boxes:</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={4}
                  name="noOfBoxes"
                  autoComplete="off"
                  value={weightData.noOfBoxes}
                  placeholder="Boxes"
                  // onChange={handleUserInput}
                  className="custom-input"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Label>BoxType:</Form.Label>
              <Form.Select
                id="dropdown"
                size="md"
                style={{ width: "225px", border: "2px solid #000" }}
                value={
                  weightData.BoxType
                    ? boxType.findIndex((bt) => bt.type === weightData.BoxType)
                    : ""
                }
                disabled
                autoComplete="off"
              >
                <option key={"select"} value={""}>
                  select
                </option>

                {boxType &&
                  boxType.map((bt, i) => {
                    return (
                      <option key={bt.id} value={i}>
                        {bt.type}
                      </option>
                    );
                  })}
              </Form.Select>
            </Col>

            <Col>
              <Form.Group controlId="input5">
                <Form.Label className="bold-label">Length(cm)</Form.Label>
                <Form.Control
                  type="text"
                  name="Length"
                  autoComplete="off"
                  value={weightData.Length}
                  placeholder="Length"
                  // onChange={handleUserInput}
                  className="custom-input"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="input6">
                <Form.Label className="bold-label">Width(cm)</Form.Label>
                <Form.Control
                  type="text"
                  name="Width"
                  autoComplete="off"
                  value={weightData.Width}
                  placeholder="Width"
                  // onChange={handleUserInput}
                  className="custom-input"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="input7">
                <Form.Label className="bold-label">Height(cm)</Form.Label>
                <Form.Control
                  type="text"
                  name="Height"
                  autoComplete="off"
                  value={weightData.Height}
                  placeholder="Height"
                  // onChange={handleUserInput}
                  className="custom-input"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="input3">
                <Form.Label className="bold-label">NetWt(kg/gm)</Form.Label>
                <Form.Control
                  type="text"
                  name="NetWt"
                  autoComplete="off"
                  value={weightData.NetWt}
                  placeholder="NetWt"
                  // onChange={handleUserInput}
                  className="custom-input"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="input4">
                <Form.Label className="bold-label">GrossWt(kg/gm)</Form.Label>
                <Form.Control
                  type="text"
                  name="GrossWt"
                  autoComplete="off"
                  value={weightData.GrossWt}
                  placeholder="GrossWt"
                  // onChange={handleUserInput}
                  className="custom-input"
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="input2">
                <Form.Label className="bold-label">Box Group:</Form.Label>
                <Form.Control
                  type="text"
                  name="BoxGroup"
                  autoComplete="off"
                  value={weightData.BoxGroup}
                  placeholder="BoxGroup"
                  // onChange={handleUserInput}
                  className="custom-input"
                  disabled
                />
              </Form.Group>
            </Col>
            {/* <Col>
              <Form.Label>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp;
              </Form.Label>
              <Button variant="primary" onClick={handleUpdate} className="btn">
                Update
              </Button>
            </Col> */}
          </Row>
        </Form>
      </div>
    </>
  );
};

export default Weights;
