import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";
import { CgMail } from "react-icons/cg";

const Footer = () => {
  const email = "mog.helpdesk@lerros.de";
  const subject = "MOG HELP";
  const handleContactWithMail = (e) => {
    e.preventDefault();
    // const mailtoLink = `mailto:mog.helpdesk@lerros.de`;
    // window.location.href = mailtoLink;
    // const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(email)}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    const gmailUrl = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
      email
    )}
    &su=${encodeURIComponent(subject)}`;
    window.open(gmailUrl, "_blank");
    console.log("heyyyy");
  };

  return (
    <footer
      className="bg-dark text-light"
      style={{
        backgroundColor: "#343a40",
        color: "white",
        // margin: "20px 0",
        position: "absolute",
        bottom: "-5px",
        // top: "92vh",
        width: "100%",
        // zIndex: 1000 /* Ensure the header and footer appear above other elements */
      }}
    >
      <Container>
        <div className="row ">
          <div className="ms-4 col-7 d-flex justify-content-end align-items-center">
            <p>&copy; 2023 Your Company. All rights reserved.</p>
          </div>
          <div className="col ">
            <a
              className="d-flex justify-content-end"
              onClick={handleContactWithMail}
              style={{
                cursor: "pointer",
                textDecoration: "none",
                color: "white",
              }}
            >
              <div>
                <span>Contact us &nbsp;</span>
              </div>

              <div>
                <CgMail style={{ height: "24px", width: "24px" }} />
              </div>
            </a>
          </div>
        </div>
      </Container>
    </footer>
  );
};
export default Footer;
