import LoadPos from "./Screens/load/LoadPos";
// import useSocket from "./hooks/useSocket";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Login from "./Screens/login";
import Orders from "./Screens/orders";
import Entries from "./Screens/entries";
import Details from "./Screens/entries/Details";
import ModifyForm from "./Screens/entries/Modify/ModifyForm";
import SpecificModifyForm from "./Screens/entries/SpecifcModify/SpecificModifyForm";
import UserService from "./Screens/adminpannel/createUser/Signup";
import ExportCsv from "./Screens/TransFerDatas/ExportCsv";
import DisplayPurchase from "./Screens/orders/DisplayPurchase";
import ModifyUser from "./Screens/adminpannel/modify user/ModifyUser";
import ProtectedRoute from "./components/ProtectedRoute";
import ShipInfo from "./Screens/ShipMent/ShipInfo";
import Boxtype from "./Screens/adminpannel/userPanel/Boxtype";
import RangeCode from "./Screens/adminpannel/userPanel/RangeCode";
import PoSummary from "./Screens/orders/PoSummary";

import EditUser from "./Screens/adminpannel/modify user/EditUser";
import Footer from "./components/Footer";
import ExFactory from "./Screens/ExFactory/ExFactory";
import BarcodePdf from "./Screens/BarcodePdf/BarcodePdf";
import ShipmentLoading from "./Screens/ShipmetnLoading/ShipmentLoading";
import Reports from "./Screens/Reports/Reports";
import MaintenanceModal from "./components/MaintenanceModel";
import { useEffect, useState } from "react";
import { useSocketContext } from "./context/socketContext";
// const Login= React.lazy(() => import("./Screens/login"));
// const Orders= React.lazy(() => import("./Screens/orders"));
// const Entries= React.lazy(() => import("./Screens/entries"));
// const Details= React.lazy(() => import("./Screens/entries/Details"));
// const ModifyForm= React.lazy(() => import("./Screens/entries/Modify/ModifyForm"));
// const UserService= React.lazy(() => import("./Screens/adminpannel/createUser/Signup"));
// const ExportCsv= React.lazy(() => import("./Screens/TransFerDatas/ExportCsv"));
// const DisplayPurchase= React.lazy(() => import("./Screens/orders/DisplayPurchase"));
// const ModifyUser= React.lazy(() => import("./Screens/adminpannel/modify user/ModifyUser"));
// const ProtectedRoute= React.lazy(() => import("./components/ProtectedRoute"));
// const ShipInfo= React.lazy(() => import("./Screens/ShipMent/ShipInfo"));
// const Boxtype= React.lazy(() => import("./Screens/adminpannel/userPanel/Boxtype"));
// const RangeCode= React.lazy(() => import("./Screens/adminpannel/userPanel/RangeCode"));
// const PoSummary= React.lazy(() => import("./Screens/orders/PoSummary"));

// const ExportCsv = React.lazy(() => import("./Screens/TransFerDatas/ExportCsv"));

function App() {
  return (
    <BrowserRouter>
      <div style={{ position: "relative", minHeight: "100vh" }}>
      <MaintenanceModal/>
        <Toaster />
        <Routes>
          <Route path="/" element={<Login />}></Route>
          {/* <Route path="/" element={<MainPage />}/> */}

          {/* <Route path="/hi" element={<MainPage />}/> */}
          <Route
            path="/orders"
            element={
              <ProtectedRoute aclKey={"orders"}>
                <Orders />
              </ProtectedRoute>
            }
          />

          <Route
            path="/DisplayPurchase"
            element={
              <ProtectedRoute aclKey={"dsp"}>
                <DisplayPurchase />
              </ProtectedRoute>
            }
          />
          <Route
            path="/PoSummary"
            element={
              // <ProtectedRoute aclKey={''}>
              <PoSummary />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/boxDetails"
            element={
              <ProtectedRoute aclKey={"boxDetails"}>
                <Entries />
              </ProtectedRoute>
            }
          />

          <Route
            path="/ShipInfo"
            element={
              <ProtectedRoute aclKey={"shipInfo"}>
                <ShipInfo />
              </ProtectedRoute>
            }
          />
          {/* <Route
            path="/Others"
            element={
              <ProtectedRoute aclKey={"others"}>
                <Other />
              </ProtectedRoute>
            }
          /> */}

          <Route
            path="/boxDetails/:seqId"
            element={
              <ProtectedRoute>
                <Details />
              </ProtectedRoute>
            }
          />
          <Route
            path="/boxDetails/modify/:seqId"
            element={
              // <ProtectedRoute aclKey="">
              <ModifyForm />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/boxDetails/specific/:seqId"
            element={
              // <ProtectedRoute aclKey="">
              <SpecificModifyForm />
              // </ProtectedRoute>
            }
          />
          <Route
            path="/csv"
            element={
              <ProtectedRoute aclKey={"csv"}>
                {/* <Suspense fallback={<Header><Spinner /></Header>}> */}
                <ExportCsv />
                {/* </Suspense> */}
              </ProtectedRoute>
            }
          />
          <Route
            path="/boxType"
            element={
              <ProtectedRoute aclKey={"boxType"}>
                <Boxtype />
              </ProtectedRoute>
            }
          />
          <Route
            path="/rangeCode"
            element={
              <ProtectedRoute aclKey={"rangeCode"}>
                <RangeCode />
              </ProtectedRoute>
            }
          />
          <Route path="/userService" element={<UserService />} />

          <Route
            path="/ModifyUser"
            element={
              <ProtectedRoute aclKey={"modifyUser"}>
                <ModifyUser />
              </ProtectedRoute>
            }
          />

          {/* <Route path="/UserPanel" element={<UserPanel />} /> */}

          {/* <Route path="/LoadPo" element={<LoadPo />} /> */}

          <Route
            path="/LoadPos"
            element={
              // #NC007 * commented protected Route, as it was giving authorization alert while login(will check to protect on it.)
              // <ProtectedRoute aclKey={"loadPO"}>
              <LoadPos />
              // </ProtectedRoute>
            }
          />

          <Route
            path="/userService/:id"
            element={
              <ProtectedRoute>
                <UserService />
              </ProtectedRoute>
            }
          />

          <Route path="/ModifyUser/:id" element={<EditUser />} />

          <Route
            path="/exFactory"
            element={
              <ProtectedRoute aclKey={"exFactory"}>
                <ExFactory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/barcodePdf"
            element={
              <ProtectedRoute aclKey={"barcodePdf"}>
                <BarcodePdf />
              </ProtectedRoute>
            }
          />
          <Route
            path="/shipmentLoading"
            element={
              <ProtectedRoute aclKey={"shipmentLoading"}>
                <ShipmentLoading />
              </ProtectedRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <ProtectedRoute aclKey={"reports"}>
                <Reports />
              </ProtectedRoute>
            }
          />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
