import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./Barchart.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const data = {
    labels: [
      "01-01-2024",
      "02-03-2024",
      "03-02-2024",
      "04-02-2024",
      "05-03-2024",
      "06-02-2024",
    ],
    datasets: [
      {
        label: "Total Po Quantity",
        backgroundColor: "blue",
        data: [30, 40, 80, 65],
      },
      {
        label: "Total Pack Quantity",
        backgroundColor: "red",
        data: [100, 200, 250, 300, 400],
      },
      {
        label: "Total Exfactory Qunatity",
        backgroundColor: "green",
        data: [120, 220, 270, 320, 500],
      },
      {
        label: "Total Ship Qunatity",
        backgroundColor: "purple",
        data: [10, 20, 30, 40, 80],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        // text: "Productivity and Cost Saving in Sewing Department",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="chart-container">
      <div className="chart">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default BarChart;
